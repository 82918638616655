import { PAGINATION_COUNT, PAGINATION_START } from "core/consts";
import {
  ArrayFilterName,
  ArrayFilterQueryParamName,
  UserFilters,
} from "./types";

export const USER_FILTER_SOLUTIONS = "solutions" as const;
export const USER_FILTER_SOLUTIONS_SINGULAR = "solution" as const;
export const USER_FILTER_CARESEEKERS = "careseekers" as const;
export const USER_FILTER_CARESEEKERS_SINGULAR = "careseeker" as const;
export const USER_FILTER_START_DATE_RANGE = "start_date_range" as const;
export const USER_FILTER_AGE_INTERVAL = "age_interval" as const;
export const USER_FILTER_GENDERS = "genders" as const;
export const USER_FILTER_GENDERS_SINGULAR = "gender" as const;
export const USER_FILTER_CARE_LEVELS = "care_levels" as const;
export const USER_FILTER_CARE_LEVELS_SINGULAR = "carelevel" as const;
export const USER_FILTER_ELECTIVE = "elective" as const;
export const USER_FILTER_PATIENT_TYPES = "patient_types" as const;
export const USER_FILTER_PATIENT_TYPES_SINGULAR = "patient_type" as const;
export const USER_FILTER_SPECIALIZATIONS = "specializations" as const;
export const USER_FILTER_SPECIALIZATIONS_SINGULAR = "specialization" as const;
export const USER_FILTER_STATIONS = "stations" as const;
export const USER_FILTER_STATIONS_SINGULAR = "station" as const;
export const USER_FILTER_SOCIAL_WORKERS = "social_worker_ids" as const;
export const USER_FILTER_SOCIAL_WORKERS_SINGULAR = "social_worker_id" as const;
export const USER_FILTER_SENDERS = "senders" as const;
export const USER_FILTER_SENDERS_SINGULAR = "sender" as const;
export const USER_FILTER_SERVICES = "services" as const;
export const USER_FILTER_SERVICES_SINGULAR = "service" as const;
export const USER_FILTER_START_DATE = "start_date" as const;
export const USER_FILTER_START_DATE_PARAM_FROM = "start_date_from" as const;
export const USER_FILTER_START_DATE_PARAM_TO = "start_date_to" as const;
export const USER_FILTER_HAS_CAPACITY = "has_capacity" as const;
export const USER_FILTER_TAB = "tab" as const;
export const USER_FILTER_TREATMENT_LOCATION = "zipcode" as const;
export const USER_FILTER_DISTANCE_FROM_PROVIDER = "distance" as const;
export const USER_FILTER_IS_DIRECT = "is_direct" as const;
export const USER_FILTER_WITH_PROVIDERS = "with_providers" as const;
export const USER_FILTER_WITH_CONSULTANTS = "with_consultants" as const;
export const USER_FILTER_WEIGHT_INTERVAL = "weight_interval" as const;
export const USER_FILTER_TRANSITIONAL_CARE = "transitional_care" as const;

export const FILTER_QUERY_ACTIONS_TYPES = {
  SET_STATE: "setState",
  RESET: "reset",
  RESET_TO_INITIAL: "resetToInitial",
  CLEAR: "clear",
} as const;

export const RECEIVER_FILTER_SETTING_PERSIST = "persist" as const;

export const fromArrayParamName: Record<
  ArrayFilterQueryParamName,
  Extract<keyof UserFilters, ArrayFilterName>
> = {
  [USER_FILTER_CARE_LEVELS_SINGULAR]: USER_FILTER_CARE_LEVELS,
  [USER_FILTER_CARESEEKERS_SINGULAR]: USER_FILTER_CARESEEKERS,
  [USER_FILTER_GENDERS_SINGULAR]: USER_FILTER_GENDERS,
  [USER_FILTER_PATIENT_TYPES_SINGULAR]: USER_FILTER_PATIENT_TYPES,
  [USER_FILTER_SENDERS_SINGULAR]: USER_FILTER_SENDERS,
  [USER_FILTER_SERVICES_SINGULAR]: USER_FILTER_SERVICES,
  [USER_FILTER_SOCIAL_WORKERS_SINGULAR]: USER_FILTER_SOCIAL_WORKERS,
  [USER_FILTER_SOLUTIONS_SINGULAR]: USER_FILTER_SOLUTIONS,
  [USER_FILTER_SPECIALIZATIONS_SINGULAR]: USER_FILTER_SPECIALIZATIONS,
  [USER_FILTER_STATIONS_SINGULAR]: USER_FILTER_STATIONS,
};

export const toArrayParamName: Record<
  ArrayFilterName,
  ArrayFilterQueryParamName
> = {
  [USER_FILTER_CARE_LEVELS]: USER_FILTER_CARE_LEVELS_SINGULAR,
  [USER_FILTER_CARESEEKERS]: USER_FILTER_CARESEEKERS_SINGULAR,
  [USER_FILTER_GENDERS]: USER_FILTER_GENDERS_SINGULAR,
  [USER_FILTER_PATIENT_TYPES]: USER_FILTER_PATIENT_TYPES_SINGULAR,
  [USER_FILTER_SENDERS]: USER_FILTER_SENDERS_SINGULAR,
  [USER_FILTER_SERVICES]: USER_FILTER_SERVICES_SINGULAR,
  [USER_FILTER_SOCIAL_WORKERS]: USER_FILTER_SOCIAL_WORKERS_SINGULAR,
  [USER_FILTER_SOLUTIONS]: USER_FILTER_SOLUTIONS_SINGULAR,
  [USER_FILTER_SPECIALIZATIONS]: USER_FILTER_SPECIALIZATIONS_SINGULAR,
  [USER_FILTER_STATIONS]: USER_FILTER_STATIONS_SINGULAR,
};

export const ARRAY_PARAM_NAMES_SINGULAR = [
  USER_FILTER_CARE_LEVELS_SINGULAR,
  USER_FILTER_CARESEEKERS_SINGULAR,
  USER_FILTER_GENDERS_SINGULAR,
  USER_FILTER_PATIENT_TYPES_SINGULAR,
  USER_FILTER_SENDERS_SINGULAR,
  USER_FILTER_SERVICES_SINGULAR,
  USER_FILTER_SOCIAL_WORKERS_SINGULAR,
  USER_FILTER_SOLUTIONS_SINGULAR,
  USER_FILTER_SPECIALIZATIONS_SINGULAR,
  USER_FILTER_STATIONS_SINGULAR,
] as const;

export const PARAM_FILTER_NAMES = [
  ...ARRAY_PARAM_NAMES_SINGULAR,
  USER_FILTER_TAB,
  USER_FILTER_START_DATE_RANGE,
  USER_FILTER_AGE_INTERVAL,
  USER_FILTER_ELECTIVE,
  USER_FILTER_START_DATE,
  USER_FILTER_START_DATE_PARAM_FROM,
  USER_FILTER_START_DATE_PARAM_TO,
  USER_FILTER_HAS_CAPACITY,
  USER_FILTER_TREATMENT_LOCATION,
  USER_FILTER_DISTANCE_FROM_PROVIDER,
  PAGINATION_COUNT,
  PAGINATION_START,
  USER_FILTER_IS_DIRECT,
  USER_FILTER_WITH_CONSULTANTS,
  USER_FILTER_WITH_PROVIDERS,
  USER_FILTER_WEIGHT_INTERVAL,
  USER_FILTER_TRANSITIONAL_CARE,
] as const;

export const ARRAY_USER_FILTER_NAMES = [
  USER_FILTER_CARE_LEVELS,
  USER_FILTER_CARESEEKERS,
  USER_FILTER_GENDERS,
  USER_FILTER_PATIENT_TYPES,
  USER_FILTER_SERVICES,
  USER_FILTER_SENDERS,
  USER_FILTER_SOCIAL_WORKERS,
  USER_FILTER_SOLUTIONS,
  USER_FILTER_SPECIALIZATIONS,
  USER_FILTER_STATIONS,
] as const;

export const USER_FILTER_NAMES = [
  ...ARRAY_USER_FILTER_NAMES,
  USER_FILTER_TAB,
  USER_FILTER_START_DATE_RANGE,
  USER_FILTER_AGE_INTERVAL,
  USER_FILTER_ELECTIVE,
  USER_FILTER_TRANSITIONAL_CARE,
  USER_FILTER_START_DATE,
  USER_FILTER_HAS_CAPACITY,
  USER_FILTER_TREATMENT_LOCATION,
  USER_FILTER_DISTANCE_FROM_PROVIDER,
  USER_FILTER_IS_DIRECT,
  USER_FILTER_WITH_CONSULTANTS,
  USER_FILTER_WITH_PROVIDERS,
] as const;

export const USER_FILTER_MAX_AGE = 120;
export const USER_FILTER_MIN_AGE = 0;
