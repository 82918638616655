import { isPastDate } from "core/model/utils/dates";
import {
  Capacity,
  Careprovider,
  NoCapacityInfo,
  QueryProgress,
} from "core/types";
import { getUnixTime } from "date-fns";
import RSButtonSaveProgress from "ds_legacy/components/RSButton/RSButtonSaveProgress";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { dp, margin, padding, sizing } from "ds_legacy/materials/metrics";
import { FONT_WEIGHT_BOLD, Title } from "ds_legacy/materials/typography";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { FormValidationNotification } from "dsl/organisms/Notifications";
import React, { ReactNode } from "react";
import styled from "styled-components";
import Translations from "translations/types";

export const FORM_WIDTH = dp(400);

export const CareproviderInfosBox = styled.div<{ hide?: boolean }>`
  margin: ${margin(0, 0, 2, 0)};
  display: ${({ hide }) => (hide ? "none" : "flex")};
  align-items: stretch;
  flex-direction: column;
  flex: 1 0 auto;
`;

export const FormBox = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: ${(props) => props.margin};
  @media screen and (max-width: ${FORM_WIDTH}) {
    & {
      max-width: 100vw;
    }
  }

  @media screen and (min-width: ${dp(420)}) {
    & {
      width: ${FORM_WIDTH};
    }
  }

  flex: 1 0 auto;
`;

export const ButtonWrapper = styled.div`
  margin: ${margin(2, 2)};
  padding: ${padding(0, 3)};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export function hasNoCapacity(capacity?: Capacity) {
  return (
    capacity?.available_date &&
    capacity.available_date > getUnixTime(new Date()) &&
    !capacity.has_capacity
  );
}

export function showExtensionsTab({
  careprovider,
}: {
  careprovider?: Careprovider | null;
}) {
  return (
    !!careprovider?.extensions?.length &&
    careprovider?.address?.country === "DE"
  );
}

export function filterToValidNoCapacities(
  noCapacities: Array<NoCapacityInfo>,
): Array<NoCapacityInfo> {
  if (noCapacities && Array.isArray(noCapacities)) {
    return noCapacities.filter((noCapacity) => {
      if (!noCapacity.expiration) {
        return false;
      }
      // we want to show only no capacity dates which are in the future
      return !isPastDate(noCapacity.expiration);
    });
  }
  return [];
}

export function SaveButton({
  children,
  id,
  onSubmitQueryProgress,
  submit,
  translations,
}: {
  children?: React.ReactNode;
  id?: string;
  onSubmitQueryProgress: QueryProgress;
  submit: () => void;
  translations: Translations;
}) {
  return (
    <ButtonWrapper data-testid="save-button" id={id}>
      {children}
      <RSButtonSaveProgress
        onSubmit={submit}
        queryProgress={onSubmitQueryProgress}
        style={{ minWidth: 150 }}
        translations={translations}
      />
      <FormValidationNotification />
    </ButtonWrapper>
  );
}

export function SettingsPageTitle({
  children,
  title,
}: {
  children?: ReactNode;
  title: string;
}) {
  const { isTablet } = useMedia();

  const Layout = isTablet ? VerticalLayout : HorizontalLayout;

  return (
    <Layout
      style={{
        alignItems: "baseline",
        margin: margin(2, 0),
        gap: isTablet ? sizing(0.5) : sizing(2),
      }}
    >
      <Title margin={margin(0)} style={{ fontWeight: FONT_WEIGHT_BOLD }}>
        {title}
      </Title>
      {children}
    </Layout>
  );
}
