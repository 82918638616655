import {
  useCreateOnPremImport,
  useGetAddressMutation,
} from "apollo/hooks/mutations";
import { useEnvContext } from "context/EnvContext";
import {
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
} from "core/consts";
import { removeSpaces } from "core/model/utils/strings";
import { Careseeker, QueryProgress } from "core/types";
import { WarningIcon } from "ds/icons";
import RSButton from "ds_legacy/components/RSButton";
import Spinner from "ds_legacy/components/Spinner";
import TextInputField from "ds_legacy/components/TextInputField";
import { ERROR_COLOR } from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { margin, padding, sizing } from "ds_legacy/materials/metrics";
import { Caption, FONT_SIZE_16 } from "ds_legacy/materials/typography";
import { useHL7ImportStatusContext } from "dsl/atoms/HL7ImportStatusContext";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { FormElementRenderProps, FormWatcher } from "react-forms-state";
import { useTracking } from "react-tracking";
import { useLocale } from "reduxentities/selectors";
import { useConnecterWithLoggedAccount } from "reduxentities/selectors/hooks";
import { useTranslations } from "translations";
import ReimportModal from "./ReimportModal";
import mutateKisPatient from "./mutateKisPatient";
import useSSLImport from "./useSSLImport";

function ClearOnChange({
  setImportStatus,
  watchedValue,
}: {
  setImportStatus: React.Dispatch<React.SetStateAction<QueryProgress>>;
  watchedValue: any;
}) {
  useEffect(() => {
    setImportStatus(QUERY_PROGRESS_NOT_STARTED);
  }, [watchedValue]);

  return null;
}

function WarningCaption({ text }: { text: string }) {
  return (
    <HorizontalLayout>
      <WarningIcon
        style={{
          color: ERROR_COLOR,
          width: sizing(2),
          height: sizing(2),
          margin: margin(0, 0, 0, 2),
        }}
        size={FONT_SIZE_16}
      />
      <Caption error maxWidth={sizing(38)} whiteSpace="normal">
        {text}
      </Caption>
    </HorizontalLayout>
  );
}

function CallImport({
  callImport,
  externalIdQueryVar,
  kis_import,
  onChangeKisHelper,
}: {
  callImport: Function;
  externalIdQueryVar: string | undefined;
  kis_import: boolean;
  onChangeKisHelper: (value: any) => void;
}) {
  useLayoutEffect(() => {
    if (externalIdQueryVar && !kis_import) {
      const timeout = setTimeout(() => {
        callImport(externalIdQueryVar, onChangeKisHelper, kis_import);
      }, 0);

      return () => clearTimeout(timeout);
    }
  }, []);

  return null;
}

export default function ExternalIdKisField({
  careseeker,
  externalIdQueryVar,
  initialPatientId,
  textLabelWidth,
}: {
  careseeker: Careseeker;
  externalIdQueryVar?: string;
  initialPatientId?: string;
  textLabelWidth: string;
}) {
  const translations = useTranslations();
  const [importStatus, setImportStatus] = useState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );
  const [externalId, setExternalId] = useState(
    initialPatientId || externalIdQueryVar,
  );

  const loggedAccount = useConnecterWithLoggedAccount({ cacheOnly: true });
  const [modalOpen, setModalOpen] = useState(false);
  const [getAddress] = useGetAddressMutation();
  const envContext = useEnvContext();
  const { trackEvent } = useTracking();
  const [createOnPremImportMutation] = useCreateOnPremImport({
    careseekerId: careseeker.id,
  });

  const { canImport, on_premise_authorization_token, on_premise_domain_ssl } =
    useHL7ImportStatusContext();
  const callImport = useSSLImport({
    on_premise_authorization_token,
    on_premise_domain_ssl,
    setImportStatus,
  });
  const locale = useLocale();

  return (
    <VerticalLayout padding={padding(0)} justify="flex-start">
      {importStatus === QUERY_PROGRESS_PENDING && (
        <HorizontalLayout padding={padding(0)}>
          <Spinner id="test" size="small" />
        </HorizontalLayout>
      )}
      <HorizontalLayout padding={padding(0)}>
        <VerticalLayout>
          <TextInputField
            elementName="external_id"
            label={translations.patient.externalId}
            width={textLabelWidth}
            sideMutation={setExternalId}
          />
          <FormWatcher watchPath="external_id">
            {({ watchedValue }) => (
              <ClearOnChange
                watchedValue={watchedValue}
                setImportStatus={setImportStatus}
              />
            )}
          </FormWatcher>
          {importStatus === QUERY_PROGRESS_FAILED && (
            <WarningCaption text={translations.patient.kis.importError} />
          )}
        </VerticalLayout>
        <FormElementRenderProps
          elementName="_kis_helper_element"
          sideMutation={(
            response: AnyObject,
            mutateElement: (value: any, name: string) => void,
          ) => {
            mutateKisPatient({
              externalId: externalId ?? "",
              createOnPremImportMutation,
              response,
              mutateElement,
              getAddress,
              loggedAccount,
              careseeker,
              envContext,
              translations,
              trackEvent,
              locale,
            });
          }}
        >
          {({
            onChange: onChangeKisHelper,
          }: {
            onChange: (value: any) => void;
          }) => (
            <FormWatcher watchPath="kis_import">
              {({ watchedValue: kis_import }) => (
                <>
                  {canImport && kis_import !== null && (
                    <CallImport
                      externalIdQueryVar={externalIdQueryVar}
                      callImport={callImport}
                      onChangeKisHelper={onChangeKisHelper}
                      kis_import={kis_import}
                    />
                  )}
                  {on_premise_domain_ssl && (
                    <RSButton
                      color="primary"
                      disabled={
                        importStatus === QUERY_PROGRESS_PENDING || !canImport
                      }
                      id="reimport_patient"
                      key="create"
                      loading="na"
                      onClick={() => setModalOpen(true)}
                      style={{ margin: margin(2, 0, 0, 0) }}
                      tooltip={
                        importStatus === QUERY_PROGRESS_PENDING
                          ? undefined
                          : canImport
                          ? translations.patient.kis.tooltipConnected
                          : translations.patient.kis.tooltipNotConnectedReimport
                      }
                      variant="text"
                    >
                      {translations.patient.kis.reimportPatientData}
                    </RSButton>
                  )}
                  <ReimportModal
                    open={modalOpen}
                    onCancel={() => setModalOpen(false)}
                    onImport={() => {
                      callImport(removeSpaces(externalId), onChangeKisHelper);
                      setModalOpen(false);
                    }}
                    translations={translations}
                  />
                </>
              )}
            </FormWatcher>
          )}
        </FormElementRenderProps>
      </HorizontalLayout>
    </VerticalLayout>
  );
}
