import {
  MAX_HEIGHT,
  MAX_WEIGHT,
  PREDICAMENT_STATE_NO,
  PREDICAMENT_STATE_UNKNOWN,
  PREDICAMENT_STATE_YES,
  VALIDATION_INVALID,
} from "core/consts";
import {
  AnyObject,
  Auction,
  Careseeker,
  GetOntologiesType,
  ToType,
} from "core/types";
import { Iterable } from "immutable";
import {
  Form,
  composeValidation,
  convertIn,
  convertOut,
  required as formRequired,
  validateModel,
} from "react-forms-state";
import { compose } from "recompose";
import { withTranslations } from "translations";
import Translations from "translations/types";
import {
  ConversionJob,
  ConversionJobs,
  ElementOptions,
  ElementOptionsT,
  MultiSelectElementOptions,
  ValidationFunction,
  WhitelistElementOptions,
  WhitelistOption,
} from "./types";

export function toJS<T>(
  maybeIterable: Parameters<typeof Iterable.isIterable>[0],
): T {
  return Iterable.isIterable(maybeIterable)
    ? maybeIterable.toJS()
    : maybeIterable;
}

const checkForTranslationsProp = (props: AnyObject) =>
  props?.translations != null;

export function isMap<K = any, V = any>(value: any): value is Map<K, V> {
  return value?.constructor?.name === "Map";
}

export const containsLink = (str: string) =>
  /(?:https?|ftp):\/\/[\n\S]+/.test(str);

export const validateTextDate = (
  date: string | null | undefined,
): boolean | string => {
  if (!date) return false;

  if (
    !date.match(
      /^(0[1-9]|[12][0-9]|3[01])[- ./](0[1-9]|1[012])[- ./](18|19|20)\d\d$/gm,
    )
  )
    return VALIDATION_INVALID;

  return true;
};

export const validateEmail = ({
  allowSpecialChars,
  email,
  required,
}: {
  allowSpecialChars?: boolean;
  email: string | null;
  required?: boolean;
}) => {
  if (!email) return required ? "empty" : true;
  const trimmed = email.trim();

  // checks for anything@domain.extension
  if (!/.*@.*\..*/.test(trimmed)) return "wrongform";
  if (trimmed.indexOf(",") > -1) return "invalidcharacter";

  // prevents two @
  if (trimmed.indexOf("@", trimmed.indexOf("@") + 1) > -1) return "twoats";

  if (!allowSpecialChars && /.*(ü|ä|ö|ß).*/.test(trimmed))
    return "invalidcharacter";
  return true;
};

export function emailValidation({
  allowSpecialChars,
  customTranslation,
  required,
}: {
  allowSpecialChars?: boolean;
  customTranslation?: (t: Translations) => string;
  required: boolean;
}) {
  return (
    email: string | null,
    props: AnyObject,
  ): boolean | { customMessage: string } => {
    const isValid = validateEmail({ email, required, allowSpecialChars });
    if (isValid === true) return true;
    return checkForTranslationsProp(props)
      ? {
          customMessage:
            customTranslation?.(props.translations) ||
            props.translations.people.invalidEmailAddress,
        }
      : false;
  };
}

export const emailModel = ({
  allowSpecialChars,
  customTranslation,
  fieldRequired,
}: {
  allowSpecialChars?: boolean;
  customTranslation?: (t: Translations) => string;
  fieldRequired: boolean;
}): ElementOptions => ({
  fieldRequired,
  convertOut: (value: ToType) => (value ? value.trim() : value),
  validate: emailValidation({
    required: fieldRequired,
    allowSpecialChars,
    customTranslation,
  }),
});

export function processPhoneNumber(number: string | null | undefined) {
  if (!number) return number;
  return number.replace(/[\s().\\/-]/g, "");
}

function removeEmptyCharacters(str: string): string {
  return str.replace(/\s+/g, "");
}

export function validatePhoneNumber(required: boolean) {
  return (
    phoneNumber: string | null | undefined,
    props: { translations: Translations },
  ): boolean | { customMessage: string } => {
    const errorResponse = checkForTranslationsProp(props)
      ? { customMessage: props.translations.people.invalidPhoneNumber }
      : false;

    const trimmedPhoneNumber = phoneNumber
      ? removeEmptyCharacters(phoneNumber)
      : "";

    if (trimmedPhoneNumber === "") {
      return required ? errorResponse : true;
    }

    const isValid = /^(\+?[0-9])[0-9\-/]*$/.test(trimmedPhoneNumber);

    return isValid ? true : errorResponse;
  };
}

export const phoneNumberModel = (required: boolean): ElementOptions => ({
  fieldRequired: required,
  convertOut: (value) => (value ? processPhoneNumber(value) : value),
  validate: (value, props) => validatePhoneNumber(required)(value, props),
});

export const requiredValidation =
  (options?: ElementOptions) => (value: ToType, props: AnyObject) => {
    if (!options?.fieldRequired) return true;
    return formRequired({
      errorString: options?.errorString?.(props.translations),
    })(value, props);
  };

export function requiredValidationT<T, FormInputValueType>(
  options?: ElementOptionsT<T, FormInputValueType>,
) {
  return (value: T, props: AnyObject) => {
    if (!options?.fieldRequired) return true;
    return formRequired({
      errorString: options?.errorString?.(props.translations),
    })(value, props);
  };
}

const linkValidation = () => (value: ToType, props: AnyObject) =>
  containsLink(value) ? props.translations.general.containsLink : true;

export const convertEncryptedFieldOut = (value: string | null) => {
  if (!value) return null;

  return { decrypted: value };
};

export const convertEncryptedFieldIn = (value: AnyObject) => {
  if (!value) return null;

  const decrypted = Iterable.isIterable(value)
    ? value.get("decrypted")
    : value.decrypted;

  if (!decrypted) return "";

  return decrypted;
};

function getValidate(options?: ElementOptions) {
  return (
    values: ToType,
    formState: {
      careseeker?: Careseeker;
      formInputValue: Auction;
      translations: Translations;
    },
    globalValue: Iterable<any, any> | null,
  ) => {
    // Bypass the validation when the field should not be displayed

    if (formState && options?.show?.(formState) === false) return true;

    if (options?.fieldRequired && options.validate)
      return composeValidation(requiredValidation(options), options?.validate)(
        values,
        formState,
      );

    if (options?.fieldRequired)
      return requiredValidation(options)(values, formState);

    if (options?.validate)
      return options.validate(values, formState, globalValue);

    return true;
  };
}

function getConvertOut(options?: ElementOptions) {
  return (
    value: any,
    props: AnyObject & { formInputValue: Auction },
    formOutputValue: ToType,
  ) => {
    // This breaks our current forms where fields can be displayed in two places.
    // This might break a bit the solution change.
    // if (
    //   options &&
    //   options.show &&
    //   props.formInputValue &&
    //   options.show(props) === false
    // )
    //   return null;
    if (options?.convertOut)
      return options.convertOut(value, props, formOutputValue);

    return value;
  };
}

function getShow(
  name: string,
  options: ElementOptions | WhitelistElementOptions | undefined,
) {
  if (options?.show) return options.show;

  const elementName = options?.out_name || name;

  if (options?.whitelist) return () => !!options?.whitelist?.[elementName];

  return undefined;
}

export const encryptedDef = <T extends string>(
  name: T,
  options?: ElementOptions,
): Record<T, ConversionJob> => {
  const show = getShow(name, options);
  options = { ...options, show };
  const validate = getValidate(options);

  return Object.assign({
    [name]: {
      show,
      out: options?.out_name || name,
      convertIn: options?.convertIn || convertEncryptedFieldIn,
      convertOut: options?.convertOut || convertEncryptedFieldOut,
      default: options?.defaultValue ?? null,
      validate,
    },
  });
};

export const whitelistEncryptedDef = <T extends string>(
  name: T,
  options: WhitelistElementOptions,
) => encryptedDef<T>(name, options);

export function valueDef<T extends string>(
  name: T,
  options?: ElementOptions,
): Record<T, ConversionJob> {
  const show = getShow(name, options);
  options = { ...options, show };
  const validate = getValidate(options);

  return Object.assign({
    [name]: {
      show,
      out: options?.out_name || name,
      convertIn: options?.convertIn,
      convertOut: getConvertOut(options),
      default: options?.defaultValue ?? null,
      errorString: options.errorString,
      validate,
    },
  });
}

export function whitelistValueDef<T extends string>(
  name: T,
  options: WhitelistElementOptions,
) {
  return valueDef<T>(name, options);
}

export function groupDef<T extends string>(
  name: T,
  children: AnyObject,
): Record<T, ConversionJob> {
  return Object.assign({
    [name]: {
      show: getShow(name, {
        show: children.show,
        whitelist: children.whitelist,
      }),
      ...valueDef(name, {
        show: getShow(name, {
          show: children.show,
          whitelist: children.whitelist,
        }),
      }),
      ...children,
    },
  });
}

export function whitelistGroupDef<T extends string>(
  name: T,
  children: AnyObject & WhitelistOption,
) {
  return groupDef<T>(name, children);
}

export const textValueDef = <T extends string>(
  name: T,
  options?: ElementOptions,
): Record<T, ConversionJob> => {
  return Object.assign({
    [name]: {
      out: options?.out_name || name,
      convertIn: options?.convertIn,
      convertOut: getConvertOut(options),
      default: options?.defaultValue ?? null,
      validate: composeValidation(
        requiredValidation(options),
        linkValidation(),
      ),
    },
  });
};

export const listDef = <T extends string>(
  name: T,
  options?: ElementOptions,
): Record<T, ConversionJob> => {
  const show = getShow(name, options);
  options = { ...options, show };
  return Object.assign({
    [name]: {
      show,
      out: name,
      convertIn: options?.convertIn,
      convertOut: options?.convertOut,
      default: options?.defaultValue || null,
      validate: options?.fieldRequired
        ? (value: ToType, props: AnyObject) =>
            value && value.size > 0 ? true : props.translations.actions.missing
        : null,
    },
  });
};

export const whitelistListDef = <T extends string>(
  name: T,
  options: WhitelistElementOptions,
) => listDef<T>(name, options);

export function algoliaReactSelectDefT<FormInputValueType>(
  name: string,
  options?: ElementOptionsT<ToType, FormInputValueType>,
) {
  const convertIn = (value: ToType) => {
    const formValue = Iterable.isIterable(value) ? value.toJS() : value;
    return formValue?.name && formValue.id
      ? {
          label: formValue?.name,
          value: formValue?.id,
        }
      : null;
  };
  const convertOut = (value: ToType) => {
    const formValue = Iterable.isIterable(value) ? value.toJS() : value;
    return {
      name: formValue?.label,
      id: formValue?.value,
    };
  };

  // @ts-ignore
  const show = getShow(name, options);
  // @ts-ignore
  options = { ...options, show };
  const validate = options?.fieldRequired
    ? composeValidation(
        requiredValidationT<ToType, FormInputValueType>(options),
        options?.validate || (() => true),
      )
    : options?.validate || (() => true);

  return Object.assign({
    [name]: {
      show,
      out: options?.out_name || name,
      default: options?.defaultValue ?? null,
      convertIn: options?.convertIn || convertIn,
      convertOut: options?.convertOut || convertOut,
      validate,
    },
  });
}

export const algoliaReactSelectDef = (name: string, options?: ElementOptions) =>
  algoliaReactSelectDefT<Auction>(name, options);

export const singleReactSelectDef = (
  name: string,
  options?: ElementOptions,
) => {
  const convertOut = (output: ToType): number | string | null => {
    const outputValue = Iterable.isIterable(output) ? output.toJS() : output;
    return outputValue ? outputValue.value : null;
  };

  const convertIn = (input: ToType): number | string | null => {
    let convertedValue = input;
    if (options?.convertIn) {
      convertedValue = options.convertIn(input);
    }
    if (convertedValue != null) return convertedValue;
    return options?.defaultValue ?? null;
  };

  const validate = options?.fieldRequired
    ? composeValidation(
        requiredValidation(options),
        options?.validate || (() => true),
      )
    : options?.validate || (() => true);

  return Object.assign({
    [name]: {
      out: name,
      default: null,
      convertIn,
      convertOut: options?.convertOut || convertOut,
      validate,
    },
  });
};

export function mapToValues(value: ToType) {
  if (Array.isArray(value)) {
    return value.map((selected) => selected.value);
  }
  return [];
}

export function multiReactSelectDef<T extends string>(
  name: T,
  { mapToValuesArray = true, ...options }: MultiSelectElementOptions = {
    mapToValuesArray: true,
  },
): Record<T, ConversionJob> {
  const convertIn = (
    input: ToType,
    props: { getOntologies: GetOntologiesType },
  ) => {
    const { getOntologies } = props;
    let inputValue = Iterable.isIterable(input) ? input.toJS() : input;
    if (!inputValue?.length && options?.defaultValue) {
      inputValue = options.defaultValue;
    }
    if (inputValue && options?.ontology) {
      const ontologyValues = getOntologies({
        type: options.ontology,
        sort: true,
      });
      return ontologyValues
        .filter((ontVal) => inputValue.includes(ontVal.value))
        .map(({ name: label, value }) => ({ label, value }));
    }
    if (
      Array.isArray(inputValue) &&
      inputValue.length > 0 &&
      // is this an array of primitive values
      Object(inputValue[0]) !== inputValue[0]
    )
      return inputValue.map((value) => ({ value, label: value }));

    return inputValue || [];
  };

  const convertOut = (value: AnyObject) => {
    const outputValue = Iterable.isIterable(value) ? value.toJS() : value;

    let converted = Array.isArray(outputValue)
      ? outputValue
      : outputValue?.value;

    if (mapToValuesArray) {
      converted = mapToValues(converted);
    }
    if (options?.convertOut) {
      return options.convertOut(converted);
    }

    return converted;
  };

  const validate = options?.validate
    ? options.validate
    : options?.fieldRequired
    ? (value: ToType, props: AnyObject) =>
        value && value.length > 0 ? true : props.translations.actions.missing
    : null;

  return Object.assign({
    [name]: {
      out: name,
      convertIn: options?.convertIn || convertIn,
      convertOut,
      validate,
    },
  });
}

// watchOutPath should follow the conversion model's `out` hierarchy and not `in`
export function convertOutAssertPath(
  watchOutPath: string,
  defaultValue: ToType,
  expectedWatchValue?: ToType,
) {
  const expected = expectedWatchValue || true;
  return (value: ToType, props?: AnyObject, allValues?: ToType) => {
    if (allValues?.getIn !== undefined) {
      if (allValues.getIn(watchOutPath.split(".")) !== expected) {
        return defaultValue;
      }
    }
    return value;
  };
}

export function getPlaceholderField(field: string) {
  return `_${field}`;
}

// populate placeholder _field's boolean value for ActivableInput
export const valueDefActivableInput = (
  fullPath: string,
  options: {
    defaultValue?: boolean;
    expectedWatchValue?: ToType;
    show?: (p: any) => boolean;
  } = {},
) => {
  const { defaultValue, expectedWatchValue, show } = options;
  const pathArr = fullPath.split(".");
  const name = pathArr[pathArr.length - 1];
  return {
    ...valueDef(getPlaceholderField(name), {
      show,
      convertIn: (value: ToType, props: AnyObject, allValues: ToType) => {
        if (allValues?.getIn !== undefined) {
          const selected = defaultValue || allValues.getIn(pathArr) != null;

          if (expectedWatchValue == 1) {
            return selected ? 1 : 0;
          }

          return selected;
        }
        return false;
      },
    }),
    ...valueDef(name, {
      convertOut: convertOutAssertPath(
        getPlaceholderField(name),
        null,
        expectedWatchValue,
      ),
    }),
  };
};

export const activableInputModelDefinition = <T extends string>(
  // Full path should be the path of the struct in our graphql model (what the backend sends)
  fullPath: string,
  // Form element name is the element name of the struct in the form.
  formElementName: T,
  children: AnyObject,
  options: {
    defaultValue?: boolean;
    expectedWatchValue?: ToType;
  } = {},
): Record<T, ConversionJob> => {
  const { defaultValue, expectedWatchValue } = options;
  const expected = expectedWatchValue || true;
  const show = getShow(formElementName, {
    show: children.show,
    whitelist: children.whitelist,
  });
  return Object.assign({
    ...valueDefActivableInput(fullPath, {
      expectedWatchValue,
      show,
      defaultValue,
    }),
    [formElementName]: {
      convertOut: convertOutAssertPath(
        getPlaceholderField(formElementName),
        null,
        expected,
      ),
      ...(show ? valueDef(formElementName, { show }) : {}),
      ...children,
    },
  });
};

export const whitelistActivableInputModelDefinition = <T extends string>(
  fullPath: string,
  formElementName: T,
  children: AnyObject & WhitelistOption,
  options: { defaultValue?: boolean; expectedWatchValue?: ToType } = {},
) =>
  activableInputModelDefinition<T>(
    fullPath,
    formElementName,
    children,
    options,
  );

export const getPredicamentOptions = (translations: Translations) => [
  { label: translations.actions.yes, value: PREDICAMENT_STATE_YES, id: "yes" },
  { label: translations.general.no, value: PREDICAMENT_STATE_NO, id: "no" },
  {
    label: translations.general.unknown,
    value: PREDICAMENT_STATE_UNKNOWN,
    id: "unknown",
  },
];

export const getPredicamentValue = ({
  label,
  translations,
  value,
}: {
  label?: string;
  translations: Translations;
  value: number | null | undefined;
}) => {
  const prefix = label ? `${label}${translations.general.colon} ` : "";
  switch (value) {
    case PREDICAMENT_STATE_YES:
      return `${prefix}${translations.actions.yes}`;
    case PREDICAMENT_STATE_NO:
      return `${prefix}${translations.general.no}`;
    case PREDICAMENT_STATE_UNKNOWN:
      return `${prefix}${translations.general.unknown}`;
    default:
      return "";
  }
};

export function createForm(
  modelDefinition: ConversionJobs,
  validation: ValidationFunction,
  formOptions?: {
    notificationOnFail?: boolean;
  },
) {
  const validate = (value: ToType, props: ToType) => {
    const valid = composeValidation(validation, validateModel(modelDefinition))(
      value,
      props,
    );

    if (valid !== true && formOptions?.notificationOnFail) {
      props.showNotification({
        message:
          props.validationMessage ?? props.translations.actions.validationError,
      });
    }
    return valid;
  };

  return compose(
    withTranslations,
    Form({
      convertIn: convertIn(modelDefinition),
      convertOut: convertOut(modelDefinition),
      modelDefinition,
      validate,
    }),
  );
}

export const maxNLetter = (max: number) => (value: ToType, props: any) => {
  if (value && value.length > max) {
    return {
      customMessage:
        props.translations.patient.formGeneration.maxLettersValidation({
          max: max.toString(),
        }),
    };
  }
  return true;
};

export const validateIBAN = (value: ToType, props: any) => {
  if (!value) return true;
  if (!/^DE([0-9a-zA-Z]\s?){20}$/.test(value)) {
    return {
      customMessage:
        props.translations.patient.formGeneration.patientBankIbanInvalid,
    };
  }

  return true;
};

export const ibanDef = (name: string) =>
  valueDef(name, {
    validate: validateIBAN,
  });

export function validateNumber(value: string | null | undefined, props: any) {
  const isValueInvalid = value !== null && !value?.match(/^(\d)*$/gm);

  if (isValueInvalid) {
    return {
      customMessage: props.translations.actions.invalid,
    };
  }

  return true;
}

export function validateNumberIsInRange({
  maxValue,
  minValue = 0,
  props,
  value,
}: {
  maxValue: number;
  minValue?: number;
  props: any;
  value: string | null | undefined;
}): boolean | { customMessage: string } {
  if (!value) return true;

  const numericValue = Number(value);

  if (
    isNaN(numericValue) ||
    numericValue < minValue ||
    numericValue > maxValue
  ) {
    return {
      customMessage: props.translations.actions.invalid,
    };
  }

  return true;
}

export function validateHeight(
  value: string | null | undefined,
  props: any,
): boolean | { customMessage: string } {
  return validateNumberIsInRange({ value, props, maxValue: MAX_HEIGHT });
}

export function validateWeight(
  value: string | null | undefined,
  props: any,
): boolean | { customMessage: string } {
  return validateNumberIsInRange({ value, props, maxValue: MAX_WEIGHT });
}

export function emailDef(
  elementName: string,
  props: {
    allowSpecialChars?: boolean;
    customTranslation?: (t: Translations) => string;
    fieldRequired: boolean;
  },
) {
  return valueDef(elementName, emailModel(props));
}
