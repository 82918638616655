import algoliasearch from "algoliasearch/lite";
import {
  useCreateOnPremImport,
  useGetAddressMutation,
} from "apollo/hooks/mutations";
import {
  INFECTION_STATUS,
  REHABILITATION_PAYMENT_OPTION,
  TRACK_EVENTS,
} from "core/consts";
import { getErrorMessage } from "core/model/utils/errors";
import {
  Account,
  Careseeker,
  ConfigType,
  EnvContext,
  OnPremImport,
  OnpremDiagnosis,
  OnpremDoctor,
  OnpremFieldStatus,
  OnpremImportStatus,
  OnpremPatient,
  PatientIsContact,
  TrackEventFn,
} from "core/types";
import { getIndex } from "dsl/atoms/AlgoliaSearchBar";
import { getDelimiter } from "test/utils/data/dates";
import Translations from "translations/types";

type InsuranceAlgoliaResult = {
  id: number;
  name: string;
  objectID: string;
  type: number;
};

type Person = {
  address_city?: string;
  address_postal_code?: string;
  address_street?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  relationship?: string;
};

type Location = {
  city?: string;
  postal_code?: string;
  street_address?: string;
};

type Insurance = {
  insurance_company_name?: string;
  insurance_id_number?: string;
  insurance_ik_number?: string;
};

type ExtraProps = {
  careseeker: Careseeker;
  envContext: EnvContext;
  getAddress?: ReturnType<typeof useGetAddressMutation>[0];
  locale: string | undefined;
  mutateElement: (value: any, name: string) => void;
  translations: Translations;
};

type TransformOut = {
  [Name in keyof OnpremPatient]: (
    valueOfName: any,
    props: ExtraProps,
  ) => Promise<void>;
};

type TransformDefinition = (
  name: keyof OnpremPatient,
  options?: {
    convert?: (value: any, props: ExtraProps) => Promise<any>;
    mutate?: (value: any, props: ExtraProps) => Promise<void>;
    out?: string;
  },
) => TransformOut;

type GroupedDiagnoses = Record<
  string,
  { code?: string; description: string }[]
>;

const transformDef: TransformDefinition = (name, options) => {
  return {
    [name]: async (valueOfName: any, props: ExtraProps) => {
      if (options?.mutate) {
        await options?.mutate(valueOfName, props);
        return;
      }
      const outName = options?.out ?? name;
      const outValue = options?.convert
        ? await options.convert(valueOfName, props)
        : valueOfName;

      if (outValue != null) props.mutateElement(outValue, outName);
    },
  };
};

export function getDiagnosisTypeTitle(
  diagnosis_type: string | undefined,
  translations: Translations,
) {
  switch (diagnosis_type) {
    case "ED":
      return translations.patient.medicalDiagnosis.diagnosisType
        .referralDiagnosis;
    case "AD":
      return translations.patient.medicalDiagnosis.diagnosisType
        .admissionDiagnosis;
    case "BD":
      return translations.patient.medicalDiagnosis.diagnosisType
        .treatmentDiagnosis;
    case "EL":
      return translations.patient.medicalDiagnosis.diagnosisType
        .dischargeDiagnosis;
    case "PR":
      return translations.patient.medicalDiagnosis.diagnosisType
        .preoperativeDiagnosis;
    case "FE":
      return translations.patient.medicalDiagnosis.diagnosisType
        .dischargeDiagnosisDepartment;
    case "PO":
      return translations.patient.medicalDiagnosis.diagnosisType
        .postoperativeDiagnosis;
    case "FB":
      return translations.patient.medicalDiagnosis.diagnosisType
        .treatmentDiagnosisDepartment;
    case "FA":
      return translations.patient.medicalDiagnosis.diagnosisType
        .admissionDiagnosisDepartment;
    case "ENTA":
      return translations.patient.medicalDiagnosis.diagnosisType
        .ambulantDischargeDiagnosis;
    case "KOND":
      return translations.patient.medicalDiagnosis.diagnosisType
        .conservativeDiagnosis;
    case "OP":
      return translations.patient.medicalDiagnosis.diagnosisType
        .operationDiagnosis;
    case "PFL":
      return translations.patient.medicalDiagnosis.diagnosisType.careDiagnosis;
    case "VER":
      return translations.patient.medicalDiagnosis.diagnosisType
        .transferDiagnosis;
    case "VLD":
      return translations.patient.medicalDiagnosis.diagnosisType
        .insuranceExtensionDiagnosis;
    case "AM":
      return translations.patient.medicalDiagnosis.diagnosisType
        .ambulanceDiagnosis;
    case "AND":
      return translations.patient.medicalDiagnosis.diagnosisType
        .anaesthesiaDiagnosis;
    case "F":
      return translations.patient.medicalDiagnosis.diagnosisType
        .departmentMainDiagnosis;
    case "FAP":
      return translations.patient.medicalDiagnosis.diagnosisType
        .examinationDiagnoses;
    case "FAND":
      return translations.patient.medicalDiagnosis.diagnosisType
        .secondaryDiagnosis;
    case "GK_EVD":
      return translations.patient.medicalDiagnosis.diagnosisType
        .childDischargeTransferDiagnosis;
    case "GK_GEB":
      return translations.patient.medicalDiagnosis.diagnosisType
        .childBirthDiagnosis;
    case "GK_MOR":
      return translations.patient.medicalDiagnosis.diagnosisType.childMorbidity;
    case "AR":
      return translations.patient.medicalDiagnosis.diagnosisType
        .invoicingDiagnosis;
    case "FABT":
      return translations.patient.medicalDiagnosis.diagnosisType
        .departmentDiagnosis;
    case "REMA":
      return translations.patient.medicalDiagnosis.diagnosisType.rehabMeasures;
    case "KUPRO":
      return translations.patient.medicalDiagnosis.diagnosisType
        .cumulativeProcedures;
    case "medical_instructions":
      return translations.patient.medicalDiagnosis.diagnosisType
        .medicalInstructions;
    case "recommended_routine":
      return translations.patient.medicalDiagnosis.diagnosisType
        .recommendedRoutine;
    case "anamnesis":
      return translations.patient.medicalDiagnosis.diagnosisType.anamnesis;
    case "evolution":
      return translations.patient.medicalDiagnosis.diagnosisType.evolution;
    case "hospital-main-diagnosis":
      return translations.patient.medicalDiagnosis.diagnosisType
        .hospitalMainDiagnosis;
    case "infection-control-diagnosis":
      return translations.patient.medicalDiagnosis.diagnosisType
        .infectionControlDiagnosis;
    case "cause-of-death":
      return translations.patient.medicalDiagnosis.diagnosisType.causeOfDeath;
    case "principle-DRG":
      return translations.patient.medicalDiagnosis.diagnosisType.principleDRG;
    case "secondary-DRG":
      return translations.patient.medicalDiagnosis.diagnosisType.secondaryDRG;
    case "OTH":
      return translations.patient.medicalDiagnosis.diagnosisType.otherDiagnosis;
    default:
      return null;
  }
}

export function generateDiagnosis(
  diagnoses: Array<OnpremDiagnosis> | undefined,
  translations: Translations,
) {
  const groupedDiagnoses: GroupedDiagnoses = {};
  diagnoses?.forEach((diagnostic: OnpremDiagnosis) => {
    const { diagnosis_code, diagnosis_description, diagnosis_type } =
      diagnostic;

    if (diagnosis_type && diagnosis_description) {
      groupedDiagnoses[diagnosis_type] = groupedDiagnoses[diagnosis_type] ?? [];
      groupedDiagnoses[diagnosis_type].push({
        code: diagnosis_code,
        description: diagnosis_description,
      });
    }
  });
  let diagnosisText = "";
  Object.entries(groupedDiagnoses).forEach(([type, diagnoses], index) => {
    if (index !== 0) diagnosisText += "\n";
    const typeTitle = getDiagnosisTypeTitle(type, translations);
    if (typeTitle) {
      diagnosisText += `${typeTitle}${translations.general.colon.trimStart()}\n`;
    }
    diagnoses.forEach(({ code, description }) => {
      if (code) diagnosisText += `${code} `;
      diagnosisText += `${description}\n`;
    });
  });
  return diagnosisText;
}

function formatGeneralPractitionerContactDetails({
  city,
  discipline,
  phoneNumber,
  phonePrefix,
  postalCode,
  street,
}: {
  city: string | undefined;
  discipline: string | undefined;
  phoneNumber: string | undefined;
  phonePrefix: string | undefined;
  postalCode: string | undefined;
  street: string | undefined;
}) {
  return [
    discipline,
    discipline && (phonePrefix || phoneNumber) ? "\n" : "",
    phonePrefix ? `${phonePrefix} ` : "",
    phoneNumber,
    street || postalCode || city ? "\n" : "",
    street && (postalCode || city) ? `${street}, ` : street,
    postalCode + " ",
    city,
  ]
    .filter(Boolean)
    .join("");
}

const cleanIKRegex = /^0*(.*)$/;

function formatGuardianOrRelative({
  city,
  firstName,
  lastName,
  phoneNumber,
  postalCode,
  relationship,
  street,
}: {
  city: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  phoneNumber: string | undefined;
  postalCode: string | undefined;
  relationship: string | undefined;
  street: string | undefined;
}) {
  return [
    firstName + " ",
    lastName,
    relationship ? ` (${relationship})` : "",
    phoneNumber ? "\n" + phoneNumber : "",
    street || postalCode || city ? "\n" : "",
    street && (postalCode || city) ? `${street}, ` : street,
    postalCode + " ",
    city,
  ]
    .filter(Boolean)
    .join("");
}

const diagnosticsToDiagnoses = (diagnostics: Array<OnpremDiagnosis>) =>
  diagnostics.map((d) => ({
    code: d.diagnosis_code,
    description: d.diagnosis_description,
    type: d.diagnosis_type,
  }));

export const cleanInsuranceId = (insurance_ik_number: string) => {
  // Some numbers have leading 0s. We're also getting old
  // ids which are not numbers (K1000 for instance.)
  const matched = insurance_ik_number.match(cleanIKRegex) || [];

  return matched?.[1];
};

export async function matchInsurance({
  config,
  env,
  insurance_company_name,
  insurance_ik_number,
}: {
  config: ConfigType | null;
  env: string;
  insurance_company_name: string | undefined;
  insurance_ik_number: string | undefined;
}) {
  const client = algoliasearch(
    config?.algolia.appId ?? "",
    config?.algolia.apiKey ?? "",
  );
  const indexName = getIndex("insurances", env, true);
  const index = client.initIndex(indexName);

  if (insurance_ik_number) {
    const cleanedIk = cleanInsuranceId(insurance_ik_number);
    if (cleanedIk) {
      const { hits } = await index.search<InsuranceAlgoliaResult>(cleanedIk, {
        hitsPerPage: 1,
      });

      if (hits?.[0]) return hits?.[0];
    }
  }

  if (insurance_company_name) {
    const { hits } = await index.search<InsuranceAlgoliaResult>(
      insurance_company_name,
      { hitsPerPage: 1 },
    );
    return hits?.[0];
  }

  return null;
}

export const HL7Definition: TransformOut = {
  ...transformDef("external_id"),
  ...transformDef("hospital_id"),
  ...transformDef("gender"),
  ...transformDef("birth_date", {
    convert: async (birthDate: string, props: ExtraProps) => {
      const delimiter = getDelimiter(props.locale);

      if (birthDate.includes(delimiter)) return birthDate;

      const firstDelimiter = birthDate
        .split("")
        .find((c) => Number.isNaN(Number(c)));
      return birthDate.replaceAll(firstDelimiter ?? "", delimiter);
    },
  }),
  ...transformDef("first_name"),
  ...transformDef("last_name"),
  ...transformDef("height"),
  ...transformDef("weight"),
  ...transformDef("admit_date_time", {
    out: "admission_date",
  }),
  ...transformDef("discharge_date_time", {
    mutate: async (discharge_date_time: number | undefined, props) => {
      if (discharge_date_time) {
        props.mutateElement(discharge_date_time, "discharge_date");
        props.mutateElement(discharge_date_time, "start_date");
      }
    },
  }),
  ...transformDef("insurance", {
    mutate: async (insurance: Insurance | undefined, props: ExtraProps) => {
      const {
        insurance_company_name,
        insurance_id_number,
        insurance_ik_number,
      } = insurance ?? {};

      if (insurance_id_number) {
        props.mutateElement(insurance_id_number, "insurance_number");
      }

      if (insurance_company_name || insurance_ik_number) {
        const match = await matchInsurance({
          config: props.envContext?.config,
          env: props.envContext?.env,
          insurance_ik_number,
          insurance_company_name,
        });

        if (match) {
          const { id, name } = match;
          props.mutateElement(id, "insurance_id");
          props.mutateElement({ id, name }, "insurance");
          props.mutateElement({ value: id, label: name }, "payers_insurance");
          props.mutateElement(
            REHABILITATION_PAYMENT_OPTION.INSURANCE,
            "selected_payment_method",
          );
        } else if (insurance_company_name || insurance_ik_number) {
          console.error(
            `could not match insurance with ik ${insurance_ik_number} and name ${insurance_company_name} in algolia`,
            {
              module: "kis_import",
            },
          );
        }
      }
    },
  }),
  ...transformDef("care_level", {
    out: "carelevel.level",
  }),
  ...transformDef("barthel_index"),
  ...transformDef("main_diagnoses", {
    out: "main_diagnosis",
    mutate: async (
      main_diagnoses: Array<OnpremDiagnosis> | undefined,
      props,
    ) => {
      if (main_diagnoses) {
        props.mutateElement(
          diagnosticsToDiagnoses(main_diagnoses),
          "hl7_main_diagnoses",
        );
      }

      props.mutateElement(
        generateDiagnosis(main_diagnoses, props.translations),
        "main_diagnosis",
      );
    },
  }),
  ...transformDef("secondary_diagnoses", {
    out: "secondary_diagnosis",
    mutate: async (
      secondary_diagnoses: Array<OnpremDiagnosis> | undefined,
      props,
    ) => {
      if (secondary_diagnoses) {
        props.mutateElement(
          diagnosticsToDiagnoses(secondary_diagnoses),
          "hl7_secondary_diagnoses",
        );
      }

      props.mutateElement(
        generateDiagnosis(secondary_diagnoses, props.translations),
        "secondary_diagnosis",
      );
    },
  }),
  ...transformDef("allergies", {
    convert: async (allergies: string) => {
      if (allergies === "") return;
      return allergies;
    },
  }),
  ...transformDef("attending_doctor", {
    out: "doctor_in_charge_in_hospital",
    convert: async (attending_doctor?: OnpremDoctor) => {
      return [
        attending_doctor?.prefix,
        attending_doctor?.degree,
        attending_doctor?.first_name,
        attending_doctor?.last_name,
      ].join(" ");
    },
  }),
  ...transformDef("station", {
    mutate: async (station: string | undefined, props: ExtraProps) => {
      if (!station) return;

      const stationObject = props.careseeker.stations_full?.find(
        (s) => s.name == station,
      );

      if (!stationObject?.id) {
        console.error(`could not match station ${station}`, {
          module: "kis_import",
        });
        return;
      }

      props.mutateElement(stationObject.id, "station_full_id");

      if (stationObject?.phone_number)
        props.mutateElement(
          stationObject?.phone_number,
          "doctor_in_charge_in_hospital_phone",
        );
    },
  }),
  ...transformDef("consulting_doctor", {
    mutate: async (
      consulting_doctor: OnpremDoctor | undefined,
      props: ExtraProps,
    ) => {
      if (consulting_doctor) {
        const generalPractitionerName = [
          consulting_doctor.prefix,
          consulting_doctor.degree,
          consulting_doctor.first_name,
          consulting_doctor.last_name,
        ]
          .filter(Boolean)
          .join(" ");

        props.mutateElement(
          generalPractitionerName,
          "general_practitioner_name",
        );

        const generalPractitionerContactDetails =
          formatGeneralPractitionerContactDetails({
            discipline: consulting_doctor.discipline,
            phonePrefix: consulting_doctor.phone_prefix,
            phoneNumber: consulting_doctor.phone_number,
            street: consulting_doctor.address_street,
            postalCode: consulting_doctor.address_postal_code,
            city: consulting_doctor.address_city,
          });

        props.mutateElement(
          generalPractitionerContactDetails,
          "general_practitioner_contact_details",
        );
      }
    },
  }),
  ...transformDef("guardians", {
    mutate: async (guardians: Array<Person> | undefined, props: ExtraProps) => {
      const guardiansInfo = guardians
        ?.map((guardian: Person) => {
          return formatGuardianOrRelative({
            firstName: guardian.first_name,
            lastName: guardian.last_name,
            relationship: guardian.relationship,
            phoneNumber: guardian.phone_number,
            street: guardian.address_street,
            postalCode: guardian.address_postal_code,
            city: guardian.address_city,
          });
        })
        .join("\n\n");
      if (guardiansInfo) {
        props.mutateElement(true, "has_guardian");
        props.mutateElement(guardiansInfo, "guardian_contact_information");
      }
    },
  }),
  ...transformDef("relatives", {
    mutate: async (relatives: Array<Person> | undefined, props: ExtraProps) => {
      const relativesInfo = relatives
        ?.map((relative: Person) => {
          return formatGuardianOrRelative({
            firstName: relative.first_name,
            lastName: relative.last_name,
            relationship: relative.relationship,
            phoneNumber: relative.phone_number,
            street: relative.address_street,
            postalCode: relative.address_postal_code,
            city: relative.address_city,
          });
        })
        .join("\n\n");
      if (relativesInfo) {
        props.mutateElement(true, "relatives_available");
        props.mutateElement(relativesInfo, "relatives_description");
      }
    },
  }),
  ...transformDef("patient_contact", {
    mutate: async (
      patient_contact: PatientIsContact | undefined,
      props: ExtraProps,
    ) => {
      const { email, home_address, phone_number } = patient_contact ?? {};
      const patientIsContactInfo = [
        phone_number,
        email,
        [
          home_address?.street_address,
          home_address?.postal_code,
          home_address?.city,
        ]
          .truthy()
          .join(", "),
      ]
        .truthy()
        .join("\n");
      if (patientIsContactInfo) {
        props.mutateElement(true, "patient_is_contact");
        props.mutateElement(
          patientIsContactInfo,
          "patient_is_contact_description",
        );
      }
    },
  }),
  ...transformDef("location", {
    out: "search_location",
    convert: async (location: Location | undefined, props: ExtraProps) => {
      const { city, postal_code, street_address } = location ?? {};
      if (street_address && city && postal_code) {
        const streetAddressParts = street_address.split(" ");
        const street = streetAddressParts
          .slice(0, streetAddressParts.length - 1)
          .join(" ");
        const house_number = streetAddressParts.slice(-1)[0];
        const zipcode = postal_code;
        let coordinates;
        let radius_in_meter;
        try {
          if (props.getAddress) {
            const data = await props.getAddress({
              city,
              street,
              zipcode,
              house_number,
              country: "DE",
            });
            coordinates = data?.data?.address?.coordinates;
            radius_in_meter = data?.data?.address?.radius;
          }
        } catch (err) {
          console.error("failed getting coordinates " + getErrorMessage(err), {
            module: "kis_import",
          });
        }
        return {
          city,
          latitude: coordinates?.latitude,
          longitude: coordinates?.longitude,
          radius_in_meter,
          street,
          encrypted_house_number: { decrypted: house_number },
          zipcode,
        };
      }
    },
  }),
  ...transformDef("infections", {
    mutate: async (infections: string | undefined, props: ExtraProps) => {
      if (infections) {
        props.mutateElement(
          INFECTION_STATUS.HAS_INFECTION,
          "infection_and_germs_state",
        );
        props.mutateElement(
          {
            mrsa: !!infections.match(/MRSA/i)?.[0],
            vre: !!infections.match(/VRE/i)?.[0],
            three_mrgn: !!infections.match(/3MRGN/i)?.[0],
            four_mrgn: !!infections.match(/4MRGN/i)?.[0],
            clostridien: !!infections.match(/clostridien/i)?.[0],
            remarks: infections,
          },
          "infection_and_germs",
        );
      }
    },
  }),
  ...transformDef("medical_history"),
};

export const EXCLUDED_FIELDS = [
  "updated_at",
  "imported_at",
  "personal_id",
  "medical_instructions",
  "recommended_routine",
  "anamnesis",
  "evolution",
];

export function mergeMainDiagnoses(hl7Import: OnpremPatient) {
  const extraDiagnoses = [];
  if (hl7Import.recommended_routine) {
    extraDiagnoses.push({
      diagnosis_type: "recommended_routine",
      diagnosis_code: "",
      diagnosis_description: hl7Import.recommended_routine,
    });
  }

  if (hl7Import.anamnesis) {
    extraDiagnoses.push({
      diagnosis_type: "anamnesis",
      diagnosis_code: "",
      diagnosis_description: hl7Import.anamnesis,
    });
  }

  if (hl7Import.medical_instructions) {
    extraDiagnoses.push({
      diagnosis_type: "medical_instructions",
      diagnosis_code: "",
      diagnosis_description: hl7Import.medical_instructions,
    });
  }

  if (hl7Import.evolution) {
    extraDiagnoses.push({
      diagnosis_type: "evolution",
      diagnosis_code: "",
      diagnosis_description: hl7Import.evolution,
    });
  }

  const updatedHl7Import = {
    ...hl7Import,
    main_diagnoses: [...(hl7Import.main_diagnoses ?? []), ...extraDiagnoses],
  };

  return updatedHl7Import;
}

export default async function mutateKisPatient({
  careseeker,
  createOnPremImportMutation,
  envContext,
  externalId,
  getAddress,
  locale,
  loggedAccount,
  mutateElement,
  response,
  trackEvent,
  translations,
}: {
  careseeker: Careseeker;
  createOnPremImportMutation: ReturnType<typeof useCreateOnPremImport>[0];
  envContext: EnvContext;
  externalId: string;
  getAddress?: ReturnType<typeof useGetAddressMutation>[0];
  locale: string | undefined;
  loggedAccount: Account | undefined;
  mutateElement: (value: any, name: string) => void;
  response: OnpremPatient;
  trackEvent: TrackEventFn;
  translations: Translations;
}) {
  const out: Map<string, ToType> = new Map<string, ToType>();

  const props = {
    getAddress,
    careseeker,
    mutateElement: (value: any, name: string) => {
      out.set(name, value);
      mutateElement(value, name);
    },
    envContext,
    translations,
    locale,
  };
  const importedFields: Array<string> = [];

  response = mergeMainDiagnoses(response);

  for (const [HL7field, HL7value] of Object.entries(response)) {
    if (EXCLUDED_FIELDS.includes(HL7field)) continue;
    const transform = HL7Definition[HL7field as keyof OnpremPatient];
    if (!transform) {
      console.error("No transform for KIS field: " + HL7field, {
        module: "kis_import",
      });
      continue;
    }
    await transform(HL7value, props);
    if (HL7value != null && HL7value != "") importedFields.push(HL7field);
  }

  // @ts-ignore
  if (response?.error) {
    createOnPremImportMutation({
      careseeker_id: careseeker.id,
      account_id: loggedAccount?.id,
      external_id: externalId,
      import_status: OnpremImportStatus.NotFound,
    });
  }

  if (importedFields.length) {
    props.mutateElement(true, "kis_import");

    createOnPremImportMutation(
      transformOut(careseeker.id, externalId, loggedAccount?.id, response, out),
    );

    trackEvent({
      name: TRACK_EVENTS.HL7_IMPORT,
      ...importedFields.reduce(
        (imported, field) => ({ ...imported, [`hl7_${field}`]: true }),
        { external_id: response.external_id },
      ),
    });
  }

  return out;
}

const getOnPremFieldStatusObject = (
  map: Map<string, ToType> | undefined,
  objectKey: string,
  key: string,
): OnpremFieldStatus => {
  if (!map) return OnpremFieldStatus.Empty;
  const obj = map.get(objectKey);
  if (!obj) return OnpremFieldStatus.Empty;
  if (obj[key as keyof typeof obj]) return OnpremFieldStatus.Imported;
  return OnpremFieldStatus.Empty;
};

const getOnPremFieldStatus = (
  map: Map<string, ToType> | undefined,
  key: string,
): OnpremFieldStatus => {
  if (!map) return OnpremFieldStatus.Empty;
  if (map.get(key)) return OnpremFieldStatus.Imported;
  return OnpremFieldStatus.Empty;
};

const getOnPremFieldStatusWithSource = (
  map: Map<string, ToType> | undefined,
  source: string | undefined,
  key: string,
): OnpremFieldStatus => {
  if (!map) return OnpremFieldStatus.Empty;
  if (map.get(key)) return OnpremFieldStatus.Imported;
  if (source) return OnpremFieldStatus.Fail;
  return OnpremFieldStatus.Empty;
};

export function transformOut(
  caresekeerId: number,
  externalId: string,
  accountId: number | undefined,
  input: OnpremPatient,
  map: Map<string, ToType>,
): OnPremImport {
  return {
    careseeker_id: caresekeerId,
    account_id: accountId,
    external_id: externalId,

    admit_date_time: getOnPremFieldStatus(map, "admission_date"),
    allergies: getOnPremFieldStatus(map, "allergies"),
    anamnesis: getOnPremFieldStatus(map, "anamnesis"),
    attending_doctor: getOnPremFieldStatus(map, "doctor_in_charge_in_hospital"),
    birth_date: getOnPremFieldStatus(map, "birth_date"),
    barthel_index: getOnPremFieldStatus(map, "barthel_index"),
    care_level: getOnPremFieldStatus(map, "carelevel.level"),
    consulting_doctor: getOnPremFieldStatus(map, "general_practitioner_name"),
    discharge_date_time: getOnPremFieldStatus(map, "discharge_date"),
    evolution: getOnPremFieldStatus(map, "evolution"),
    first_name: getOnPremFieldStatus(map, "first_name"),
    gender: getOnPremFieldStatus(map, "gender"),
    guardians: getOnPremFieldStatus(map, "guardian_contact_information"),
    height: getOnPremFieldStatus(map, "height"),
    import_status: OnpremImportStatus.Success,
    infections: getOnPremFieldStatus(map, "infection_and_germs"),

    insurance_name: getOnPremFieldStatusWithSource(
      map,
      input.insurance?.insurance_company_name ||
        input.insurance?.insurance_ik_number ||
        undefined,
      "insurance_id",
    ),
    insurance_number: getOnPremFieldStatus(map, "insurance_number"),
    last_name: getOnPremFieldStatus(map, "last_name"),
    patient_city: getOnPremFieldStatusObject(map, "search_location", "city"),
    patient_postal_code: getOnPremFieldStatusObject(
      map,
      "search_location",
      "zipcode",
    ),
    patient_address: getOnPremFieldStatusObject(
      map,
      "search_location",
      "street",
    ),
    main_diagnoses: getOnPremFieldStatus(map, "main_diagnosis"),
    medical_history: getOnPremFieldStatus(map, "medical_history"),
    medical_instructions: getOnPremFieldStatus(map, "medical_instructions"),
    recommended_routine: getOnPremFieldStatus(map, "recommended_routine"),
    relatives: getOnPremFieldStatus(map, "relatives_description"),
    secondary_diagnoses: getOnPremFieldStatus(map, "secondary_diagnosis"),
    station: getOnPremFieldStatusWithSource(
      map,
      input.station,
      "station_full_id",
    ),
    weight: getOnPremFieldStatus(map, "weight"),
  };
}
