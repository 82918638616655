import {
  ENV_DEMO,
  ENV_DEVELOPMENT,
  ENV_PREPROD,
  ENV_STAGING,
} from "core/model/config";
import { useParams } from "react-router-dom";

type NumberOrUndefinedParams<Params> = {
  [p in keyof Params]: number | undefined;
};

export function getReferrer() {
  const fallback = "app";
  try {
    return getQueryVariable(location.search, "ref") || fallback;
  } catch (err) {
    return fallback;
  }
}

export function useIntParams<
  Params extends NumberOrUndefinedParams<Params> = Record<
    string,
    number | undefined
  >,
>(fallback: Partial<Params> = {}): Params {
  const params = useParams();
  const result = Object.entries({ ...fallback, ...params }).reduce<Params>(
    (accumulated, [key, value]) => {
      const number = parseInt(value as string);
      const isNumber = !isNaN(number);
      return {
        ...accumulated,
        [key]: isNumber ? number : fallback[key as keyof Params],
      };
    },
    {} as Params,
  );
  return result;
}

export function getQueryVariable(
  search: string,
  variable: string,
): string | undefined {
  const urlParameters = new URLSearchParams(search);
  return urlParameters.get(variable) ?? undefined;
}

export function getQueryBooleanVariable(search: string, variable: string) {
  const urlParameters = new URLSearchParams(search);
  return urlParameters.get(variable) === "";
}

export function getQueryIntArrayVariable(search: string, key: string) {
  const queryParam = getQueryVariable(search, key);

  if (!queryParam) return undefined;

  return queryParam
    .substring(1, queryParam.length - 1)
    .split(",")
    .map((s) => parseInt(s));
}

export function getQueryIntVariable(search: string, key: string) {
  const queryParam = getQueryVariable(search, key);

  if (!queryParam) return undefined;

  const result = parseInt(queryParam);

  if (isNaN(result)) {
    console.log(
      `Could not parse querystring param ${key} (${queryParam}) into int`,
    );
    return undefined;
  }

  return result;
}

export function computeUrl(url: string): string {
  const httpRegex = /(http)s?:\/\//g;
  return url.match(httpRegex) ? url : `http://${url}`;
}

export const getUrlEnvSuffix = (env: string) => {
  let suffix;
  switch (env) {
    case ENV_DEVELOPMENT:
    case ENV_STAGING:
      suffix = "-staging";
      break;
    case ENV_PREPROD:
      suffix = "-preprod";
      break;
    case ENV_DEMO:
      suffix = "-demo";
      break;
    default:
      suffix = "";
  }
  return suffix;
};

export const getTopLevelDomain = () => {
  const country = window?.location?.host.split(".").slice(-1)[0];
  switch (country) {
    case "fr":
      return "FR";
    default:
      return "DE";
  }
};

const acceptedOriginDomains = [
  ".recaresolutions.com",
  ".recaresolutions.fr",
  "http://localhost:9090",
];

export const isRecareOrigin = (origin: string) => {
  return acceptedOriginDomains.some((acceptedDomain) =>
    origin.endsWith(acceptedDomain),
  );
};

export function getFormattedURL(website: string) {
  if (website.indexOf("http://") == 0 || website.indexOf("https://") == 0) {
    return website;
  }
  return `//${website}`;
}

export const pathToUrl = ({
  pathname,
  search,
}: {
  pathname: string | undefined;
  search: string | undefined;
}): string =>
  [pathname, search]
    .truthy()
    .join(search?.length && !search.startsWith("?") ? "?" : "");
