import { UNDO_REQUEST_ACTION_SUGGEST_PROVIDER } from "core/consts";
import { AuctionRequest, QueryProgress } from "core/types";
import {
  EventSpecsEnhanced,
  getCancelAction,
} from "dsl/atoms/EventSpecs/utils";
import { useCareseekerNavigationHandlers } from "dsl/hooks/useNavigationHandlers";
import { useUndoRequestAction } from "dsl/hooks/useRequestAction";
import EventCard, { EventCardProps } from "dsl/molecules/Messenger/EventCard";
import { useNotification } from "dsl/organisms/NotificationProvider";
import { useCallback } from "react";
import { ConnecterWithLoggedAccount } from "reduxentities/selectors";
import { useTranslations } from "translations";

export type EventCardCareseekerProps = {
  auctionRequest: AuctionRequest;
  detached?: boolean;
  event: EventSpecsEnhanced;
  isDividerVisible: EventCardProps["isDividerVisible"];
  print?: boolean;
  shouldShowBefore: EventCardProps["shouldShowBefore"];
};

const useOnCancelActionCareseeker = (
  auctionRequest: AuctionRequest,
): [() => Promise<void>, QueryProgress] => {
  const { patient: patientNavigationHandler } =
    useCareseekerNavigationHandlers();
  const notify = useNotification();
  const translations = useTranslations();
  const [undoAction, undoActionQueryProgress, undoActionType] =
    useUndoRequestAction({
      auctionRequest,
    });
  const onCancel = useCallback(async () => {
    await undoAction({
      onCompleted: () => {
        if (undoActionType === UNDO_REQUEST_ACTION_SUGGEST_PROVIDER) {
          patientNavigationHandler.goToSearch({
            patientId: auctionRequest.auction?.patient?.id ?? -1,
            auctionId: auctionRequest.auction_id ?? -1,
          });
        }
      },
      onError: () => {
        notify({ message: translations.auctionRequest.tryAgain });
      },
    });
  }, [auctionRequest, undoAction]);
  return [onCancel, undoActionQueryProgress];
};

export default function EventCardCareseeker({
  auctionRequest,
  detached,
  event,
  isDividerVisible,
  print,
  shouldShowBefore,
}: EventCardCareseekerProps) {
  const [onCancel, onCancelSubmitProgress] =
    useOnCancelActionCareseeker(auctionRequest);

  return (
    <EventCard
      cancelAction={getCancelAction({
        onCancel,
        eventType: event.type,
        requestActions: auctionRequest?.request_actions,
      })}
      cardFormat={{
        ...event.cardFormat,
        lastSeens: detached ? undefined : event.cardFormat.lastSeens,
      }}
      eventMessages={event.eventMessages}
      file={event.file}
      Icon={event.Icon}
      isDividerVisible={isDividerVisible}
      isEventUserInitiated={event.isEventUserInitiated}
      messages={event.messages}
      onSubmitQueryProgress={onCancelSubmitProgress}
      print={print}
      searchType={event.searchType}
      sentByLabel={event.sentByLabel}
      shouldShowBefore={shouldShowBefore}
      statusHeader={event.statusHeader}
      timestamp={event.timestamp}
      type={event.type}
    />
  );
}

export const ConnectedEventCardCareseeker =
  ConnecterWithLoggedAccount<EventCardCareseekerProps>(EventCardCareseeker);
