import {
  AUCTION_REQUEST_ACCEPTED,
  AUCTION_REQUEST_ATTRIBUTED,
  AUCTION_REQUEST_CREATED,
  AUCTION_REQUEST_DECLINED,
  AUCTION_REQUEST_REJECTED,
  AUCTION_REQUEST_SENT,
  AUCTION_REQUEST_UNAVAILABLE,
  DIFF_FIELDS,
  FILTER_TYPES,
  REQUEST_ACTION_SEND_FILE,
  REQUEST_ACTION_SEND_MESSAGE,
  REQUEST_ACTION_UNDO_TYPES,
  UNDO_REQUEST_ACTION_ACCEPT_REQUEST,
  UNDO_REQUEST_ACTION_MANUALLY_ARCHIVED,
  UNDO_REQUEST_ACTION_REJECT_PROVIDER,
  UNDO_REQUEST_ACTION_REQUEST_DECLINATION,
  UNDO_REQUEST_ACTION_SUGGEST_PROVIDER,
} from "core/consts";
import { EVENTS } from "core/consts/events";
import { isProd } from "core/model/utils/featureFlags";
import {
  ONTOLOGY_SERVICE,
  ONTOLOGY_SPECIALIZATIONS,
  OntologyType,
} from "core/model/utils/ontologies";
import {
  AuctionRequest,
  Event,
  EventFilter,
  GetOntologyType,
  RequestActionType,
  RequestActionTypes,
  RequestActions,
  UndoActionType,
} from "core/types";
import Translations from "translations/types";

const getOntologies = (
  type: OntologyType,
  arr: Array<number>,
  getOntology: GetOntologyType,
) => arr.map((key) => getOntology({ type, key })).join(", ");

export function getRejectedProviderFilterReason(
  filters: Array<EventFilter>,
  translations: Translations,
  getOntology: GetOntologyType,
): string {
  return (
    filters
      ?.map(({ type, value, values = [] }) => {
        let filterValues: number[] = [];

        // TODO: Remove when value merged into values in BE
        if (value) filterValues = [value];
        if (values?.length) filterValues = [...filterValues, ...values];

        filterValues = [...new Set(filterValues)];

        switch (type) {
          case FILTER_TYPES.FILTER_SOLUTION:
            return translations.ontologies.solution.key;
          case FILTER_TYPES.FILTER_AGE:
            return translations.careproviderCard.age;
          case FILTER_TYPES.FILTER_DISTANCE:
          case FILTER_TYPES.FILTER_ZIPCODES:
            return translations.address.address;
          case FILTER_TYPES.FILTER_SERVICE:
          case FILTER_TYPES.FILTER_PREREQUISITE:
            return getOntologies(ONTOLOGY_SERVICE, filterValues, getOntology);
          case FILTER_TYPES.FILTER_SPECIALIZATION:
            return getOntologies(
              ONTOLOGY_SPECIALIZATIONS,
              filterValues,
              getOntology,
            );
          case FILTER_TYPES.FILTER_CAPACITY_START_DATE:
            return translations.patient.expectedStartDate;
          case FILTER_TYPES.FILTER_REMOVED_SOLUTION:
            return translations.requestEvents.removedSolution;
          case FILTER_TYPES.FILTER_WEIGHT:
            return translations.patient.formGeneration.weight;
          default:
            console.error(
              "RejectedProviderFilterReason:",
              type,
              "is not handled",
            );
            return translations.general.unknown;
        }
      })
      .join(", ") || ""
  );
}

export function getStatus(status: number, translations: Translations) {
  switch (status) {
    case AUCTION_REQUEST_ACCEPTED:
      return translations.search.summary.accepted;
    case AUCTION_REQUEST_CREATED:
      return translations.search.summary.sentFax;
    case AUCTION_REQUEST_SENT:
      return translations.search.summary.sent;
    case AUCTION_REQUEST_ATTRIBUTED:
      return translations.search.summary.approved;
    case AUCTION_REQUEST_REJECTED:
      return translations.search.summary.rejected;
    case AUCTION_REQUEST_DECLINED:
      return translations.search.summary.declined;
    case AUCTION_REQUEST_UNAVAILABLE:
      return translations.search.summary.unavailable;
    default:
      console.log("Missing translation for ", status);
      return null;
  }
}

function getFieldTranslationComputer(
  translations: Translations,
): (field: string) => string {
  const translationsMap: Map<string, string> = new Map([
    [
      DIFF_FIELDS.AUCTION_RADIUS_IN_METER,
      translations.patient.diffFields.searchRadius,
    ],
    [DIFF_FIELDS.AUCTION_SOLUTIONS, translations.patient.diffFields.solutions],
    [DIFF_FIELDS.DIAGNOSIS, translations.patient.diffFields.diagnosis],
    [DIFF_FIELDS.CARE_NEEDS, translations.patient.diffFields.patientCondition],
    [DIFF_FIELDS.TRANSPORT, translations.patient.diffFields.transport],
    [
      DIFF_FIELDS.SOCIAL_WORKER_ID,
      translations.patient.diffFields.socialWorker,
    ],
    [DIFF_FIELDS.STATION_FULL_ID, translations.patient.diffFields.station],
    [DIFF_FIELDS.MENTAL_HEALTH, translations.patient.diffFields.mentalHealth],
    [
      DIFF_FIELDS.PHYSICAL_DISABILITIES,
      translations.patient.diffFields.mobility,
    ],
    [DIFF_FIELDS.AUCTION_SERVICES, translations.ontologies.service.key],
    [
      DIFF_FIELDS.AUCTION_SPECIALIZATION,
      translations.ontologies.specializations.key,
    ],
    [DIFF_FIELDS.PROFILE, translations.patient.diffFields.profile],
  ]);

  const extendedMap: Map<string, string> = new Map([]);

  // Handle auction update event
  translationsMap.forEach((value, key) => {
    extendedMap.set(key, value);
    if (key.startsWith("Auction."))
      extendedMap.set(key.replace("Auction.", ""), value);
  });

  const mapped = (field: string) => {
    let fieldTranslation = "";
    let savedLength = 0;
    extendedMap.forEach((translation, key) => {
      if (field.startsWith(key) && savedLength < key.length) {
        savedLength = key.length;
        fieldTranslation = translation;
      }
    });
    if (!fieldTranslation && !isProd) {
      console.error(`Field '${field}' is not handled for patient update event`);
    }

    return fieldTranslation;
  };

  return mapped;
}

export function getPatientUpdatedFields({
  diffs,
  joint = "\n",
  translations,
}: {
  diffs: Array<string> | null | undefined;
  joint?: string;
  translations: Translations;
}) {
  if (!diffs || !(diffs.length > 0)) return;

  const computer = getFieldTranslationComputer(translations);
  const fieldTranslations: Array<string> = diffs
    .map(computer)
    .filter((v) => !!v);
  if (!fieldTranslations.length) return;

  return fieldTranslations
    .filter((f, i) => fieldTranslations.indexOf(f) === i)
    .join(joint);
}

export function getBouncedEmailsTranslations(
  event: Event | null | undefined,
  translations: Translations,
) {
  const bouncedMails = event?.context?.bounced_mails;
  if (bouncedMails?.total_bounced == null || bouncedMails?.total_sent == null)
    return translations.requestEvents.mailBounced;

  const delivered = (
    bouncedMails.total_sent - bouncedMails.total_bounced
  ).toString();
  const total = bouncedMails.total_sent.toString();

  return translations.requestEvents.requestSomeMailBounced({
    count: delivered,
    total,
  });
}

export const findRequestAction = (
  auctionRequest: AuctionRequest,
  actionType: RequestActionType,
) =>
  auctionRequest?.request_actions?.find(
    (requestAction: RequestActionTypes) =>
      requestAction.action_type === actionType,
  );

export const getUndoActionType = ({
  requestActions,
}: {
  requestActions?: RequestActions;
}): UndoActionType => {
  const undoAction = requestActions?.find(
    ({ action_type }: RequestActionTypes) =>
      REQUEST_ACTION_UNDO_TYPES.includes(action_type as UndoActionType),
  );

  return (undoAction?.action_type as UndoActionType) || "";
};

export const UNDO_ACTION_MAPPING: {
  [index in Partial<UndoActionType>]: {
    cancelType?: string;
    requestActionEvent?: number;
  };
} = {
  [UNDO_REQUEST_ACTION_REQUEST_DECLINATION]: {
    cancelType: UNDO_REQUEST_ACTION_REQUEST_DECLINATION,
    requestActionEvent: EVENTS.REQUEST_DECLINED,
  },
  [UNDO_REQUEST_ACTION_ACCEPT_REQUEST]: {
    cancelType: UNDO_REQUEST_ACTION_ACCEPT_REQUEST,
    requestActionEvent: EVENTS.REQUEST_ACCEPTED,
  },
  [UNDO_REQUEST_ACTION_MANUALLY_ARCHIVED]: {
    cancelType: UNDO_REQUEST_ACTION_MANUALLY_ARCHIVED,
    requestActionEvent: EVENTS.REQUEST_MANUALLY_ARCHIVED,
  },
  [UNDO_REQUEST_ACTION_SUGGEST_PROVIDER]: {
    cancelType: UNDO_REQUEST_ACTION_SUGGEST_PROVIDER,
    requestActionEvent: EVENTS.REQUEST_TO_BE_SENT,
  },
  [UNDO_REQUEST_ACTION_REJECT_PROVIDER]: {
    cancelType: UNDO_REQUEST_ACTION_REJECT_PROVIDER,
    requestActionEvent: EVENTS.RESPONSE_REJECTED,
  },
};

export const isSingleSolutionAccept = (auctionRequest: AuctionRequest) =>
  (auctionRequest?.response?.accepted_solutions?.length || 0) <= 1;

export function isFilesActivatedOnRequest(auctionRequest: AuctionRequest) {
  return !!findRequestAction(auctionRequest, REQUEST_ACTION_SEND_FILE);
}

export function isMessagingActivatedOnRequest(auctionRequest: AuctionRequest) {
  return !!findRequestAction(auctionRequest, REQUEST_ACTION_SEND_MESSAGE);
}
