import {
  HOME_CARE_SOLUTIONS,
  MEDICAL_SUPPLIES_SOLUTIONS,
  SOLUTION_FULL_TIME_MOBILE_CARE,
  SOLUTION_HELP_AT_HOME,
  SOLUTION_MOBILE_CARE,
  TRANSPORT_SOLUTIONS,
} from "core/consts";
import {
  getRandomCoordinates,
  preprocessZipcode,
  validateZipcode,
} from "core/model/utils/location";
import {
  convertModelDefinition,
  encryptedDef,
  required,
} from "react-forms-state";

const houseNumberSolutions = [
  SOLUTION_MOBILE_CARE,
  SOLUTION_HELP_AT_HOME,
  SOLUTION_FULL_TIME_MOBILE_CARE,
  ...TRANSPORT_SOLUTIONS,
  ...MEDICAL_SUPPLIES_SOLUTIONS,
  ...HOME_CARE_SOLUTIONS,
];

export const requiredHouseNumber = (solutions: Array<number> | undefined) => {
  if (!solutions) return false;
  return !solutions.every((s) => houseNumberSolutions.indexOf(s) < 0);
};

export const getModelDefinition = (
  country: any,
  encryptionActivated: boolean,
) =>
  convertModelDefinition({
    city: {
      out: "city",
      default: null,
      validate: required(),
    },
    street: {
      out: "street",
      default: null,
      validate: required(),
      convertOut: (value: any) => value.trim(),
    },
    floor: {
      out: "floor",
      default: null,
      convertOut: (value: any) => value?.trim() || null,
    },
    house_number: {
      out: "house_number",
      default: null,
      validate: (value: any, props: any) =>
        requiredHouseNumber(props.solutions) && encryptionActivated
          ? required()(value)
          : true,
    },
    ...encryptedDef("encrypted_house_number"),
    zipcode: {
      out: "zipcode",
      default: null,
      // @ts-expect-error
      convertOut: preprocessZipcode(),
      validate: validateZipcode(true),
    },
    country: {
      out: "country",
      default: null,
      convertOut: () => country,
    },
  });

const regexMap = {
  DE: /^(.*)\s+(\d+\w*)$/,
  FR: /^(\d+\w*)\s+(.+)$/,
};

function extractHouseNumberDE(matched: Array<string>) {
  const [, street, house_number] = matched;
  return [street, house_number];
}

function extractHouseNumberFR(matched: Array<string>) {
  const [, house_number, street] = matched;
  return [street, house_number];
}

export function extractHouseNumber(
  address: string | undefined,
  countryCode: "DE" | "FR",
) {
  if (!address) return [];

  const regex = regexMap[countryCode];

  const trimmed = address.trim();
  if (!regex.test(trimmed)) return [trimmed, ""];

  const matched = trimmed.match(regex) || [trimmed, trimmed, ""] || [];

  if (countryCode === "FR") return extractHouseNumberFR(matched);
  return extractHouseNumberDE(matched);
}

export const getLocation = (suggestion: {
  city: string;
  countryCode: string;
  hit?: { population: number };
  latlng?: { lat: number; lng: number };
  name: string;
  postcode?: Array<string> | string;
}) => {
  if (!suggestion.name) return null;

  const radius = 500;
  const coordinates = getRandomCoordinates(
    {
      longitude: suggestion?.latlng?.lng || -1,
      latitude: suggestion?.latlng?.lat || -1,
    },
    radius,
  );

  const countryCode = suggestion.countryCode.toUpperCase() as "DE" | "FR";
  const [street, house_number] = extractHouseNumber(
    suggestion.name,
    countryCode,
  );

  if (/\d/.test(street))
    console.error(
      `${street} - The extracted address contains a number. It might or might not be a problem ${suggestion.name}`,
    );

  return {
    street,
    house_number,
    encrypted_house_number: house_number,
    radius,
    // Sometimes postcode is an array
    zipcode:
      suggestion?.postcode && Array.isArray(suggestion.postcode)
        ? suggestion.postcode.length > 0 && suggestion.postcode[0]
        : suggestion.postcode,
    city: suggestion.city,
    country: countryCode,
    coordinates,
    population: suggestion?.hit?.population,
  };
};
