import {
  AUCTION_REQUEST_ACCEPTED,
  AUCTION_REQUEST_ATTRIBUTED,
  AUCTION_REQUEST_DECLINED,
  AUCTION_REQUEST_DECLINED_REASON_NO_CAPACITY,
  AUCTION_REQUEST_DECLINED_REASON_NO_CAPACITY_FOR_PATIENT,
  AUCTION_REQUEST_DECLINED_REASON_NO_CAPACITY_FOR_PATIENT_H2H,
  AUCTION_REQUEST_DECLINED_REASON_OTHER,
  AUCTION_REQUEST_DECLINED_REASON_PAYER,
  AUCTION_REQUEST_DECLINED_REASON_UNATTRACTIVE_LOCATION,
  AUCTION_REQUEST_REJECTED,
  AUCTION_REQUEST_SENT,
  AUCTION_REQUEST_UNAVAILABLE,
  AUCTION_STATUS_ACCEPTED,
  AUCTION_STATUS_CANCELLED,
  AUCTION_STATUS_IN_PROGRESS,
  AUCTION_STATUS_MANUALLY_RUNNING,
  AUCTION_STATUS_NOT_STARTED,
  AUCTION_STATUS_SUCCESS,
  GENDER_FEMALE,
  INFECTION_AND_GERMS_REQUIRES_ISOLATION,
  NO_CAPACITY_FOR_CARELEVEL,
  NO_CAPACITY_FOR_GENDER,
  NO_CAPACITY_FOR_INFECTION,
  NO_CAPACITY_FOR_ROOM,
  NO_CAPACITY_FOR_SERVICE,
  NO_CAPACITY_FOR_SOLUTION,
  NO_CAPACITY_FOR_SPECIALIZATION,
  SEARCH_ACTION_DISPLAY_FILES_TAB,
  SEARCH_ACTION_RESTART_SEARCH,
  SEARCH_ACTION_START_AUTOMATIC_SEARCH,
  SEARCH_ACTION_START_MANUAL_SEARCH,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOME_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_REHABILITATION,
  SEARCH_TYPE_TRANSPORT,
  TRACK_EVENTS,
} from "core/consts";
import { getName } from "core/model/accounts";
import {
  Auction,
  AuctionRequest,
  AuctionResponse,
  Event,
  EventContext,
  GetOntologyType,
  NoCapacityReason,
  PatientWhitelistProps,
  PatientWhitelistScope,
  SearchAction,
  SearchActionType,
  SearchType,
  ToType,
  TrackEventFn,
} from "core/types";
import { format, fromUnixTime } from "date-fns";
import { shouldHandleTime } from "dsl/ecosystems/CareproviderSettingsCapacity/utils";
import mergeWith from "lodash/mergeWith";
import { useTranslations } from "translations";
import Translations from "translations/types";
import { useGetTranslationForSearchType } from "../utils/ontologies/hooks";
import { getAuctionStopReasonTranslation } from "./stopReasons";

export const AUCTION_RUNNING_STATUSES = [
  AUCTION_STATUS_IN_PROGRESS,
  AUCTION_STATUS_MANUALLY_RUNNING,
  AUCTION_STATUS_ACCEPTED,
];

export const isAuctionRunning = (status: number | null | undefined) =>
  (status && AUCTION_RUNNING_STATUSES.includes(status)) || false;

export const useGetAuctionStopReason = (auction: Auction) => {
  const translations = useTranslations();
  const getTranslationForSearchType = useGetTranslationForSearchType();
  const searchType = auction?.search_type;
  const stopReason = auction?.cancel_auction?.reason;
  const notNeededTranslation = getTranslationForSearchType({
    translationKey: "patient.stopAuction.notNeeded",
    receiverType: "receiverTypeSingular",
    searchType: searchType,
  });
  const reason = getAuctionStopReasonTranslation({
    translations,
    stopReason,
    notNeededTranslation,
  });
  return reason;
};

export const getAuctionRequestStatus = (
  auctionRequest: AuctionRequest | null | undefined,
) => {
  if (auctionRequest?.status == null) return AUCTION_REQUEST_UNAVAILABLE;

  return auctionRequest.status;
};

export const getAuctionRequestStatusString = (
  auctionRequest: AuctionRequest | null,
) => {
  const status = getAuctionRequestStatus(auctionRequest);

  switch (status) {
    case AUCTION_REQUEST_UNAVAILABLE:
      return "Unavailable";
    case AUCTION_REQUEST_SENT:
      return "Sent";
    case AUCTION_REQUEST_DECLINED:
      return "Declined by CP";
    case AUCTION_REQUEST_ACCEPTED:
      return "Accepted by CP";
    case AUCTION_REQUEST_REJECTED:
      return "Rejected by SW";
    case AUCTION_REQUEST_ATTRIBUTED:
      return "Attributed by SW";
    default:
      return status.toString();
  }
};

export function getReasonTranslation({
  getOntology,
  reason,
  translations,
  value,
}: {
  getOntology: GetOntologyType;
  reason: number;
  translations: Translations;
  value: any;
}) {
  switch (reason) {
    case NO_CAPACITY_FOR_SPECIALIZATION:
      return getOntology({ type: "specializations", key: value });
    case NO_CAPACITY_FOR_INFECTION:
      if (value === INFECTION_AND_GERMS_REQUIRES_ISOLATION) {
        return getOntology({ type: "infectionAndGerms", key: value });
      }
      return `${
        translations.auctionResponse.noCapacityReasons.treatmentOfPatientsWith
      } ${getOntology({ type: "infectionAndGerms", key: value })}`;
    case NO_CAPACITY_FOR_SERVICE:
      return getOntology({ type: "service", key: value });
    case NO_CAPACITY_FOR_SOLUTION:
      return getOntology({ type: "solution", key: value });
    case NO_CAPACITY_FOR_ROOM:
      return getOntology({ type: "roomType", key: value });
    case NO_CAPACITY_FOR_GENDER:
      if (value == GENDER_FEMALE) {
        return translations.auctionResponse.noCapacityReasons.femaleRoom;
      }
      return translations.auctionResponse.noCapacityReasons.maleRoom;
    case NO_CAPACITY_FOR_CARELEVEL: {
      if (value == 0) {
        return translations.auctionResponse.noCapacityReasons
          .treatmentOfPatientsWithoutCarelevel;
      }
      return `${translations.auctionResponse.noCapacityReasons.treatmentOfPatientsWithCarelevel} ${value}`;
    }
    default:
      return "";
  }
}

function getReasonsText({
  getOntology,
  reasons,
  translations,
  withComma,
  withHyphen,
}: {
  getOntology: GetOntologyType;
  reasons: NoCapacityReason[] | undefined;
  translations: Translations;
  withComma?: boolean;
  withHyphen?: boolean;
}) {
  const reasonText: string[] = [];

  if (!reasons) return "";
  if (reasons)
    reasons.forEach(({ type, values }) =>
      values.forEach((value: ToType) => {
        reasonText.push(
          `\n${withHyphen ? "- " : ""}` +
            getReasonTranslation({
              reason: type,
              value,
              translations,
              getOntology,
            }),
        );
      }),
    );
  return reasonText.join(withComma ? ", " : " ");
}

export function getAuctionForDynamo(auction: Auction) {
  return {
    id: auction.id,
    search_type: auction.search_type,
    solutions: auction.solutions,
    specializations: auction.specializations,
    assessment_variant: auction.assessment_variant,
    patient: {
      careseeker: auction.patient.careseeker,
    },
  };
}

export function declineDetailsReason({
  getOntology,
  isSearchMergeTable,
  locale,
  options,
  searchType,
  translations,
  withComma,
  withHyphen,
}: {
  getOntology: GetOntologyType;
  isSearchMergeTable?: boolean;
  locale: Locale;
  options: AuctionResponse | EventContext | null | undefined;
  searchType: SearchType | undefined;
  translations: Translations;
  withComma?: boolean;
  withHyphen?: boolean;
}): string | null {
  if (options?.declined_reason) {
    switch (options.declined_reason) {
      case AUCTION_REQUEST_DECLINED_REASON_NO_CAPACITY_FOR_PATIENT: {
        const reasons = getReasonsText({
          reasons: options.no_capacity_reasons,
          translations,
          getOntology,
          withHyphen,
          withComma,
        });

        if (reasons) {
          return `${translations.auctionRequest.noCapacityForPatientReason}:${reasons}`;
        }
        return translations.auctionRequest.noCapacityForPatientReason;
      }
      case AUCTION_REQUEST_DECLINED_REASON_NO_CAPACITY_FOR_PATIENT_H2H: {
        if (options.message) {
          return `${translations.auctionRequest.noCapacityForPatientReason} - ${options.message}`;
        }
        return translations.auctionRequest.noCapacityForPatientReason;
      }
      case AUCTION_REQUEST_DECLINED_REASON_NO_CAPACITY:
        if (options.capacity_date) {
          return translations.auctionResponse.declinedReasonNoCapacity({
            date: shouldHandleTime({ searchType })
              ? format(fromUnixTime(options.capacity_date), "P, HH:mm", {
                  locale,
                })
              : format(fromUnixTime(options.capacity_date), "P", { locale }),
          });
        }
        return translations.auctionResponse.declinedReasonNoCapacityNoDate;
      case AUCTION_REQUEST_DECLINED_REASON_UNATTRACTIVE_LOCATION:
        return translations.auctionRequest.geographicallyUnattractive;
      case AUCTION_REQUEST_DECLINED_REASON_PAYER: {
        const message = options.message ? `\n${options.message}` : "";
        return `${translations.auctionResponse.declinedReasonPayer}${message}`;
      }
      case AUCTION_REQUEST_DECLINED_REASON_OTHER:
        if (options.message)
          return isSearchMergeTable
            ? options.message
            : translations.auctionResponse.declinedReasonOther({
                reasonDescription: options.message,
              });

        return null;
      default:
        return null;
    }
  }

  return null;
}

export const isAuctionSuccess = (status: number | null | undefined) =>
  status === AUCTION_STATUS_SUCCESS;

export const isRequestAvailable = (
  request: AuctionRequest | null | undefined,
) => {
  const requestStatus = getAuctionRequestStatus(request);
  const auctionStatus = request?.auction?.status;
  const auctionCancelledAndWon =
    [AUCTION_STATUS_CANCELLED, AUCTION_REQUEST_DECLINED].includes(
      requestStatus || -1,
    ) &&
    [AUCTION_STATUS_SUCCESS, AUCTION_STATUS_CANCELLED].includes(
      auctionStatus || -1,
    );
  return (
    requestStatus !== AUCTION_REQUEST_REJECTED &&
    !auctionCancelledAndWon &&
    requestStatus !== AUCTION_REQUEST_UNAVAILABLE
  );
};

export function getAuctionStatusString(status: number | null | undefined) {
  switch (status) {
    case AUCTION_STATUS_NOT_STARTED:
      return "not started";
    case AUCTION_STATUS_IN_PROGRESS:
      return "in progress";
    case AUCTION_STATUS_SUCCESS:
      return "success";
    case AUCTION_STATUS_CANCELLED:
      return "cancelled";
    case AUCTION_STATUS_MANUALLY_RUNNING:
      return "manually running";
    default:
      return "unknown";
  }
}

export const auctionSuccessEvent = (
  event: Event,
  translations: Translations,
  getOntology: GetOntologyType,
  auctionSuccessUnknownTranslation: string,
) => {
  const providerName =
    event.careprovider?.name ?? event?.context?.careprovider_name;
  const accountName = getName(event.account, getOntology) ?? "";
  if (event.context?.manual) {
    return providerName
      ? translations.timeline.auctionSuccessProviderKnown({
          name: providerName,
          accountName,
        })
      : auctionSuccessUnknownTranslation;
  }

  return translations.timeline.auctionSuccess;
};

const isObject = (v: any) => typeof v === "object" && v !== null;

export function mergeAuction(oldAuction: Auction, newAuction: Auction) {
  const recursive = (oldValue: any, newValue: any): any => {
    if (Array.isArray(newValue)) return newValue;
    return isObject(newValue)
      ? mergeWith(oldValue, newValue, recursive)
      : newValue;
  };

  return mergeWith(oldAuction, newAuction, recursive);
}

export function measureAssessmentCompletion({
  auction,
  sessionId,
  step,
  trackEvent,
}: {
  auction: Readonly<Auction>;
  sessionId: string | undefined;
  step: string;
  trackEvent: TrackEventFn;
}) {
  trackEvent({
    name: TRACK_EVENTS.ASSESSMENT_COMPLETION,
    step,
    session_id_from_assessment: sessionId,
    variant: auction.assessment_variant,
    search_type: auction.search_type,
    solutions: auction.solutions
      ?.clone()
      ?.sort((a, b) => a - b)
      .join(","),
    specializations: auction.specializations?.concat().sort().join(","),
  });
}

export function validateScope({
  careseeker: careseekerProp,
  formInputValue,
}: PatientWhitelistProps) {
  return function validateScopeWithProps(s: PatientWhitelistScope) {
    const careseeker = careseekerProp ?? formInputValue?.patient?.careseeker;
    if (!careseeker) {
      console.error("no careseeker providered for whitelist validation");
    }

    if (
      s.search_type != null &&
      formInputValue != null &&
      formInputValue?.search_type != s.search_type
    )
      return false;

    if (s.country != null && careseeker?.address?.country != s.country)
      return false;

    if (s.sender_type != null && careseeker?.type != s.sender_type)
      return false;

    if (
      formInputValue != null &&
      s.solutions != null &&
      !s.solutions.some(
        (solution) => formInputValue?.solutions?.includes(solution),
      )
    )
      return false;

    return true;
  };
}

export function descriptiveWhitelist(scopes: PatientWhitelistScope[]) {
  return (props: PatientWhitelistProps) => scopes.some(validateScope(props));
}

// Due to transport searches having auto validation, the user cannot
// control the solution that is validated as a solution for this,
// transport searches are limited to a single solution.
// Coupled with transport searches rarely requiring multiple different
// modes of transport for a single patient
export const isMultiSolutionSearch = (searchType: SearchType) =>
  searchType !== SEARCH_TYPE_TRANSPORT;

export const showPatientDistance = (searchType: SearchType | undefined) =>
  searchType &&
  !(
    [SEARCH_TYPE_MEDICAL_SUPPLIES, SEARCH_TYPE_HOME_CARE] as SearchType[]
  ).includes(searchType);

export const findSearchAction = (
  auction: Auction,
  actionType: SearchActionType,
) =>
  auction.search_actions?.find(
    (searchAction) => searchAction.action_type === actionType,
  );

export const getSearchActions = (
  auction: Auction | null,
): Record<string, never> | { [action in SearchActionType]: SearchAction } =>
  auction?.search_actions?.reduce(
    (acc, currentAction) => ({
      ...acc,
      [currentAction.action_type]: currentAction,
    }),
    {},
  ) || {};

export function isFilesActivatedAtAuctionLevel(auction: Auction) {
  const { [SEARCH_ACTION_DISPLAY_FILES_TAB]: action } =
    getSearchActions(auction);
  return !!(action && !action.context?.disabled);
}

export const getSearchRadius = ({
  country,
  searchType,
}: {
  country?: string;
  searchType: SearchType | null | undefined;
}) => {
  switch (searchType) {
    case SEARCH_TYPE_HOSPITAL:
      if (country === "FR") return 150;
      return 600;
    case SEARCH_TYPE_REHABILITATION:
      return 600;
    case SEARCH_TYPE_CARE:
      return 200;
    default:
      return 200;
  }
};

export function getRadiusOptions({
  country,
  searchType,
}: {
  country: string;
  searchType: SearchType | null | undefined;
}) {
  return {
    step: 5,
    min: 5,
    max: getSearchRadius({ searchType, country }),
    unit: "km",
  };
}

export const canStartSearch = (auction: Auction): boolean => {
  const startSearchActions = [
    SEARCH_ACTION_START_AUTOMATIC_SEARCH,
    SEARCH_ACTION_START_MANUAL_SEARCH,
    SEARCH_ACTION_RESTART_SEARCH,
  ];
  return !!auction.search_actions?.find((searchAction) =>
    startSearchActions.includes(searchAction.action_type),
  );
};

export function isRehab(
  searchType: SearchType | undefined,
): searchType is typeof SEARCH_TYPE_REHABILITATION {
  return searchType === SEARCH_TYPE_REHABILITATION;
}
export function isCare(
  searchType: SearchType | undefined,
): searchType is typeof SEARCH_TYPE_CARE {
  return searchType === SEARCH_TYPE_CARE;
}

export function isTransport(
  searchType: SearchType | undefined,
): searchType is typeof SEARCH_TYPE_TRANSPORT {
  return searchType === SEARCH_TYPE_TRANSPORT;
}

export function isHospital(
  searchType: SearchType | undefined,
): searchType is typeof SEARCH_TYPE_HOSPITAL {
  return searchType === SEARCH_TYPE_HOSPITAL;
}

export const isHomeCareMedicalSupplies = (auction: Auction): boolean =>
  (
    [SEARCH_TYPE_HOME_CARE, SEARCH_TYPE_MEDICAL_SUPPLIES] as SearchType[]
  ).includes(auction.search_type);
